<template>
  <button
    v-if="!href"
    class="v-button"
    :class="[
      `v-button__${type}`,
      size && `v-button__${size}`,
      {'is-disabled': disabled, 'is-plain': plain, 'is-inline': inline}
    ]"
    :style="computedStyle"
    v-on="buttonEvents"
  >
    <slot />
  </button>
  <a
    v-else
    :href="href"
    class="v-button v-button__link"
    :class="[
      `v-button__${type}`,
      size && `v-button__${size}`,
      {'is-disabled': disabled, 'is-plain': plain}
    ]"
    :style="computedStyle"
    :target="target"
  >
    <slot />
  </a>
</template>

<script>
import debounce from 'lodash.debounce';

export default {
  name: 'VButton',
  props: {
    /**
     * @content 按钮类型
     * @param primary 主要按钮
     * @param text  文字按钮
     * @param white  白色按钮
     * @param white-primary 白色 + 主色按钮
     * @param primary-gradient 主色渐变
     */
    type: {
      type: String,
      default: 'default',
    },
    /**
     * @content 按钮大小
     * @param default 高度 32 padding 16
     * @param big 高度 47 padding 47
     * @param pro 高度 60  padding 72
     * @param medium 高度 44
     * */
    size: {
      type: String,
      default: '',
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 按钮宽度
    width: {
      type: [Number, String],
      default: 0,
    },
    // 按钮高度
    height: {
      type: [Number, String],
    },
    // 是否是幽灵按钮
    plain: {
      type: Boolean,
      default: false,
    },
    // 跳转链接,如果存在这个属性,那么 button 渲染为 a 标签
    href: {
      type: String,
      default: '',
    },
    // 如果是 a 标签的话,接受 target 属性,使用这个属性的时候,必须有 href 才行
    target: {
      type: String,
      default: '',
    },
    // 节流时间
    throttleTime: {
      type: Number,
      default: 200,
    },
    // 是否沾满整行
    inline: {
      type: Boolean,
      default: false,
    },
    buttonStyle: {
      type: Object,
    },
  },
  computed: {
    buttonEvents() {
      const $this = this;
      const clickFun = debounce($this.$listeners?.click || (() => {}), this.throttleTime, {
        leading: true,
        trailing: false,
      });
      return {
        ...this.$listeners,
        click: (event) => {
          if ($this.disabled) {
            this.$emit('disabledClick');
            return;
          }
          clickFun(event);
        },
      };
    },
    computedStyle() {
      const styles = {};
      if (this.width) {
        Object.assign(styles, {
          width: `${this.width}px`,
        });
      }
      if (this.height) {
        Object.assign(styles, {
          height: `${this.height}px`,
          lineHeight: `${this.height}px`,
        });
      }

      Object.assign(styles, this.buttonStyle);
      return styles;
    },
  },
};
</script>

<style lang="less">
.v-button {
  vertical-align: middle;
  display: inline-block;
  padding: 0 14px;
  height: 32px;
  border: 1px solid #E8E8E8;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all .3s;
  line-height: 29px;
  font-family: DINPro-Medium;
}

.v-button.is-inline {
  width: 100%;
}

.v-button + .v-button {
  margin-left: 12px;
}

.v-button__pro + .v-button__pro {
  margin-left: 30px;
}

.v-button__medium + .v-button__medium {
  margin-left: 18px;
}

/* type */
.v-button__default:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}

.v-button__primary {
  background-color: var(--main-color);
  color: #fff;
  border-color: transparent;
}

.v-button__primary:hover {
  background-color: var(--main-color-hover);
}

.v-button__primary-gradient {
  background-image: var(--main-gradient);
  color: var(--main-text-color);
  border-color: transparent;
}

.v-button__primary-gradient:hover {
  background-image: var(--main-gradient-hover)
}

.v-button__primary.is-plain {
  border-color: var(--main-color);
  color: var(--main-color);
  background-color: transparent;
  background-image: none;
}

.v-button__primary.is-plain:hover {
  border-color: transparent;
  background-image: var(--main-gradient);
  color: #fff;
}

.v-button__white {
  background-color: #fff;
  border: 1px solid #fff;
  color: var(--main-color);
}

.v-button__white-primary {
  background-color: #fff;
  border: 1px solid #fff;
  color: var(--main-color);
}

.v-button__white-primary:hover {
  color: rgba(var(--main-color-rgb),0.8);
}

.v-button__white-primary.is-plain {
  background-color: transparent;
  color: #fff;
}

.v-button__white-primary.is-plain:hover {
  background-color: transparent;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}

.v-button__white:hover {
  color: rgba(var(--main-color-rgb),0.8);
}

.v-button__white.is-plain {
  background-color: transparent;
  color: #fff;
}

.v-button__white.is-plain:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: var(--main-color);
}

.v-button__text {
  border: none;
  background: transparent;
  color: #fff;
  transition: all 0.3s;
}

.v-button__text:hover {
  color: fadeout(#fff, 20)
}

.v-button.is-disabled{
  background: #E8E8E8;
  color:fadeout(#333,50);
}

/* size */
.v-button__pro {
  padding: 0 70px;
  height: 60px;
  font-size: 20px;
  line-height: 60px;
}

.v-button__big + .v-button__pro {
  margin-left: 48px;
}
.v-button__primary.v-button__pro:not(.is-plain) {
  box-shadow: 0 17px 30px 0 rgba(var(--main-color-rgb), 0.33);
}

.v-button__primary.v-button__pro.is-plain:hover {
  box-shadow: 0 17px 30px 0 rgba(var(--main-color-rgb), 0.33);
}

.v-button__big {
  padding: 0 23px;
  height: 48px;
  font-size: 16px;
  line-height: 48px;
}

.v-button__medium {
  height: 44px;
  font-size: 14px;
  padding: 0 20px;
  line-height: 44px;
}

.v-button__big + .v-button__big {
  margin-left: 48px;
}

.v-button__white.v-button__pro:not(.is-plain),
.v-button__white.v-button__big:not(.is-plain) {
  box-shadow: 0 17px 30px 0 rgba(var(--main-color-rgb), 0.33);
}
.v-button__white.v-button__pro.is-plain:hover {
  box-shadow: 0 17px 30px 0 rgba(var(--main-color-rgb), 0.33);
}
</style>
