var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.href)?_c('button',_vm._g({staticClass:"v-button",class:[
    ("v-button__" + _vm.type),
    _vm.size && ("v-button__" + _vm.size),
    {'is-disabled': _vm.disabled, 'is-plain': _vm.plain, 'is-inline': _vm.inline}
  ],style:(_vm.computedStyle)},_vm.buttonEvents),[_vm._t("default")],2):_c('a',{staticClass:"v-button v-button__link",class:[
    ("v-button__" + _vm.type),
    _vm.size && ("v-button__" + _vm.size),
    {'is-disabled': _vm.disabled, 'is-plain': _vm.plain}
  ],style:(_vm.computedStyle),attrs:{"href":_vm.href,"target":_vm.target}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }