import './public.css';
import vueScroll from 'vuescroll/dist/vuescroll-native';
// 指令
import directives from './directives';
// 命令调用组件
import Toast from './Toast';
import AlertBox from '@components/Public/AlertBox';

export default {
  install(Vue) {
    // 指令
    Object.keys(directives).forEach((key) => {
      const value = directives[key];
      Vue.directive(key, value);
    });

    Vue.prototype.$_toast = Toast;
    Vue.prototype.$_alert = AlertBox;
    Vue.use(vueScroll);
  },
};
