<template>
  <el-container direction="vertical" style="background: #FFFFFF">
    <div class="banner">
      <img src="@assets/banner_suanlishichang.png" alt="" style="width: 100%;"/>
      <div class="content-container">
        <h2 v-html="$t('text116')"></h2>
        <h2 v-html="$t('text118')"></h2>
        <p>{{$t('text117')}}</p>
      </div>

    </div>
   <el-main>
     <div class="tabheader">
       <div class="tab container">
         <div :class="['tabItem',{activebc:current==0}]" @click="miaopush('quanqiuyewubuju',0)">
           <img src="@assets/tabselect.png" height="100%" width="100%" />
            <span>{{$t('text119')}}</span>

         </div>
         <div :class="['tabItem',{activebc:current==1}]" @click="miaopush('dashiji',1)">
           <span>{{$t('text120')}}</span>
           <img src="@assets/tabselect.png" height="100%" width="100%" class="activebc"/>
         </div>
         <div  :class="['tabItem',{activebc:current==2}]" @click="miaopush('xuanzewomen',2)">
           <span>{{$t('text121')}}</span>
           <img src="@assets/tabselect.png" height="100%" width="100%" class="activebc"/>
         </div>
         <div  :class="['tabItem',{activebc:current==3}]" @click="miaopush('shangwuhezuo',3)">
           <span>{{$t('text122')}}</span>
           <img src="@assets/tabselect.png" height="100%" width="100%" class="activebc"/>
         </div>
         <div :class="['tabItem',{activebc:current==4}]" @click="miaopush('chengyuan',4)">
           <span>{{$t('text123')}}</span>
           <img src="@assets/tabselect.png" height="100%" width="100%" class="activebc"/>
         </div>
<!--         <div :class="['tabItem',{activebc:current==5}]" @click="miaopush('hezuohuoban2',5)">
           <span>{{$t('text124')}}</span>
           <img src="@assets/tabselect.png" height="100%" width="100%" class="activebc"/>
         </div>-->
       </div>
     </div>

    <div style="height:50px "></div>
<!--     全球业务布局-->
     <div class="container" id="quanqiuyewubuju">
       <h2 class="title">{{$t('text119')}}</h2>
       <p class="dectext" style="text-align: center">{{getWebsiteConfigCache('title')}}{{$t('text125')}}</p>

       <img src="@assets/defaultdec.png" style="max-width: 100%;margin-top: 50px" v-if="currentLan=='el_GR'"/>
       <img src="@assets/defaultdec_en_US.png" style="max-width: 100%;margin-top: 50px" v-else />

     </div>

<!--     發展歷程·大事記-->
     <div class="container">
       <h2 class="title" id="dashiji" style="margin-top: 80px">{{$t('text120')}}</h2>

        <img src="@assets/timepic.png" style="max-width: 896px;margin:0 auto;display: block;margin-top: 50px;margin-bottom: 90px" v-if="currentLan=='el_GR'"/>
        <img src="@assets/timepic_en_US.png" style="max-width: 896px;margin:0 auto;display: block;margin-top: 50px;margin-bottom: 90px" v-else />

     </div>

<!-- 為什麽選擇我們 -->
     <div class="container">
       <h2 class="title" id="xuanzewomen">{{$t('text121')}}</h2>
       <div class="blockView">
         <div class="blockviewItem">
            <img src="@assets/ic_kuangjicaigou.png" class="icon2"/>
            <h2>{{$t('text126')}}</h2>
            <p>{{$t('text127')}}</p>
         </div>
         <div class="blockviewItem">
           <img src="@assets/ic_jifangtuoguan.png" class="icon2"/>
           <h2>{{$t('text135')}}</h2>
           <p>{{$t('text136')}}</p>
         </div>
         <div class="blockviewItem">
           <img src="@assets/ic_suishitixian.png" class="icon2"/>
           <h2>{{$t('text137')}}</h2>
           <p>{{$t('text138')}}</p>
         </div>
         <div class="blockviewItem">
           <img src="@assets/ic_quanqiuhegui.png" class="icon2"/>
           <h2>{{$t('text139')}}</h2>
           <p>{{$t('text140')}}</p>
         </div>
       </div>
     </div>


     <div class="container bccc">
       <h2>{{$t('text128')}}</h2>
       <p>{{getWebsiteConfigCache('title')}}{{$t('text129')}}</p>
       <p>{{getWebsiteConfigCache('title')}}{{$t('text131')}}</p>
       <p>{{$t('text132')}}</p>

     </div>

     <div class="container bccc link">
       <h2>{{$t('text133')}}</h2>
       <a href="https://github.com/libp2p/go-libp2p/issues/467">1.https://github.com/libp2p/go-libp2p/issues/467</a><br>
       <a href="https://canx.me/posts/ipfs-cluster-considered-unnecessary">2.https://canx.me/posts/ipfs-cluster-considered-unnecessary</a>
     </div>

     <div class="container" style="margin-top: 30px" id="deckuanchi">
       <h2>{{$t('text205')}}</h2>
       <h3>1.{{$t('text206')}}</h3>
       <p>{{$t('text207')}}</p>
       <h3>2.{{$t('text208')}}</h3>
       <p>{{$t('text209')}}</p>
       <h3>4.{{$t('text210')}}</h3>
       <p>{{$t('text211')}}</p>
       <h3>5.{{$t('text212')}}</h3>
       <p>{{$t('text213')}}</p>
       <h3>6.{{$t('text214')}}</h3>
       <p>{{$t('text215')}}</p>
       <h3>7.{{$t('text216')}}</h3>
       <p>{{$t('text217')}}</p>
<!--       <img src="@assets/pic_banner_2.png" style="max-width:100%" v-if="currentLan=='el_GR'"/>-->
<!--       <img src="@assets/pic_banner_2_en_US.png" style="max-width:100%" v-else/>-->
     </div>



     <!--商務合作 -->
     <div class="container" id="shangwuhezuo">
       <h2 class="title" style="margin-top: 80px">{{$t('text122')}}</h2>
        <img src="@assets/shangwuhezuo.png" style="max-width: 100%" v-if="currentLan=='el_GR'"/>
        <img src="@assets/shangwuhezuo_en_US.png" style="max-width: 100%" v-else/>
     </div>


<!--     團隊成員-->
     <div class="container">
       <h2 class="title" id="chengyuan">{{$t('text123')}}</h2>
       <p class="dectext">{{getWebsiteConfigCache('title')}}{{$t('text134')}}</p>

       <img src="@assets/mappic.png" style="max-width: 885px;display: block;margin:0px auto;margin-top: 30px;margin-bottom: 90px" v-if="currentLan=='el_GR'"/>
       <img src="@assets/mappic_en_US.png" style="max-width: 885px;display: block;margin:0px auto;margin-top: 30px;margin-bottom: 90px" v-else/>
     </div>


<!--
     <div class="container">
       <h2 class="title" id="hezuohuoban2">{{$t('text124')}}</h2>
       <div class="centerView" id="hezuohuoban">
         <el-row style="display: flex;align-items: center;margin-top: 20px">
           <img src="@assets/logo_press_nvidia.png" class="icon" alt="logo_nivdia"/>

           <img src="@assets/logo_press_langchao.png" class="icon" alt="logo_press_langchao"/>
           <img src="@assets/logo_press_bb.png" class="icon" alt="logo_press_bb"/>
           <img src="@assets/logo_press_chainup.png" class="icon" alt="logo_press_chainup"/>
           <img src="@assets/logo_press_jiedianziben.png" class="icon" alt="logo_press_jiedianziben"/>
         </el-row>

         <el-row style="display: flex;align-items: center;margin-top: 20px">

           <img src="@assets/logo_press_huobi.png" class="icon" alt="@assets/logo_press_huobi.png"/>

           <img src="@assets/logo_press_ali.png" class="icon" alt="@assets/logo_press_ali.png"/>

           <img src="@assets/logo_press_jinse.png" class="icon" alt="@assets/logo_press_jinse.png"/>

           <img src="@assets/logo_press_tv.png" class="icon" alt="@assets/logo_press_tv.png"/>
           <img src="@assets/logo_press_jiedianziben2.png" class="icon" alt="@assets/logo_press_jiedianziben2.png"/>
         </el-row>
       </div>
     </div>
-->


   </el-main>
  </el-container>
</template>

<script>
import {getCookie} from "../../utils/cookieUtil";

export default {
  name: "aboutus",
  data(){
    return {
      current:0,
      currentLan:'en_US'
    }
  },
  methods:{

    getWebsiteConfigCache(attr) {
      const config = this.$store.state.market.websiteConfig;
      console.log('state.data getWebsiteConfigCache:', config, attr)
      if (null != config && config[attr]) {
        return config[attr];
      } else {
        return attr;
      }
    },
    miaopush(urlname,index){
      this.current=index;
      this.scrollTo(urlname);
    },
    scrollTo(ele, speed){
      //速度默认值
      if(!speed) speed = 200;
      const targetElement = document.getElementById(ele);
      window.scroll({top:targetElement.offsetTop-98-50,behavior:"smooth"})
      return false;
    }

  },
  mounted() {
    const tabheader = document.getElementsByClassName("tabheader")[0];
    const ViewTop = (tabheader.offsetTop-98);
    this.currentLan=getCookie("lan");

    window.onscroll=()=>{
      //tabheader.offsetTop
      if(scrollY>=ViewTop){
        tabheader.style.position="fixed";
        tabheader.style.top = "98px";
      }else{
        tabheader.style.position="absolute";
        tabheader.style.top = (350+98)+'px';
      }
    }
  }
}
</script>

<style scoped>

.container{
  width:1200px;
  margin:0 auto;
}
.banner{
  height: 350px;
  width: 100%;
  position: relative;
}
.banner{
  height: 350px;
  overflow: hidden;
}
.banner img{max-width: 100%;height: 100%}

.ada h2,.ada p{
  color: #FFFFFF;
}
.ada h2{
  font-size: 50px;

}

.ada p{
  font-size: 16px;
  line-height: 25px;
}
.ada{
  left: 230px;
  top: 90px;
}
.el-main{padding: 0}
.tabheader{background-color: #0E0E0E;width: 100%;height: 50px;position: absolute;top: calc(350px + 98px);z-index: 100}
.tab{
  display: flex;

}
.tab .tabItem{
  flex:1;
  height: 50px;
  position: relative;
  text-align: center;
  line-height: 50px;
  overflow: hidden;
}
.tabItem img{
  width: 100%;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  display:none
}
.activebc img{
  display: block;
}
.activebc span{
  color: #fff!important;

}
.title{
  font-size: 32px;
  color: #333;
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.dectext{
  font-size: 16px;
  color: #999;
  text-align: left;
}

.tab .tabItem span{color: #B0B0B0;font-size: 14px}

.blockView{
  display: flex;

}

.icon2{
  width: 50px;
  height: 50px;
  margin-top: 44px;

}
.blockView{width: 100%;}
.blockviewItem{
  width: 285px;
  height: 228px;
  background: #F7F9FC;
  text-align: center;
  margin-right: 20px;
  /*padding:0 20px*/
}
.blockviewItem:last-child {
  margin-right: 0;
}
.blockviewItem h2{
  font-size: 24px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}
.blockviewItem p{
  font-size: 16px;
  color: #999;
}

.bccc{
  height: 260px;
  background: url('~@/assets/pic_banner_1.png') no-repeat center;
  background-size: contain;
  margin-top: 30px;
  overflow: hidden;
}
.bccc h2{
  margin-left: 60px;
  font-size: 24px;
  color: #333;
  margin-top:60px;
  margin-bottom: 20px;
}
.bccc a,.bccc p{
  margin-left: 60px;
  font-size: 18px;
  color: #999999;
}
.link a{
  color:#3E26D1
}


.icon {
  height: 100px;
  flex: 1;
  margin: 0 15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.icon:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#hezuohuoban{
  margin-bottom: 90px;
}
.tabItem:hover>img{
  display: block;
}
.tabItem:hover>span{
  color: #fff;
}

#deckuanchi{
  background: #fff;
  box-shadow: 0 0 10px #eee;
  width: 1105px;
  padding:50px 40px;
}
#deckuanchi h2{
  font-size: 24px;
  color: #333333;
}
#deckuanchi h3{
  font-size: 18px;
  color: rgb(54,54,54);
  margin-top: 30px;
}
#deckuanchi p{
  margin-top: 10px;
  color: rgb(152,152,152);
  font-size: 18px;
  line-height: 30px;
}
.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
}
.content-container h2 {
  font-size: 50px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 70px;
}
.content-container p {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
</style>
