<template>
  <div style="padding:20px">
  <p style="font-size:26pt; line-height:115%; margin:0pt;text-align: center;margin-bottom: 20px"><span
      style="color:#333333; font-family:-apple-system; font-size:26pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">隱私條款</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們尊重用戶和網站訪問者（統稱為“您”）的隱私，並承諾盡力保護您的在線隱私。本隱私政策概述了</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> </span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">storage</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（以下稱為“</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">”或“我們”）管理、收集、維護和處理用戶個人數據的流程。我們可能不時更新本隱私政策。我們會在此頁面上發布新的隱私政策來通知您任何變更。我們建議您定期查看此隱私政策來了解變更。本隱私政策的變更自其發佈在此網頁之時生效。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">一、信息收集</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們可能會使用您的個人數據來提供和改進</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">向您提供的雲算力服務（“服務”），並改善您在本網站上的使用體驗。 “個人數據”是指與被識別或可識別的人有關的任何信息。當您為使用服務創建帳戶時，我們會收集您提供給我們的信息。我們還使用各種技術來收集和存儲信息，包括Cookie、像素標籤、本地存儲（如瀏覽器網絡存儲或應用程序數據緩存、數據庫和服務器日誌）。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">您使用服務或本網站，即表示您同意我們按照本隱私政策收集和使用信息。除非本隱私政策另有規定，否則本隱私政策中使用的術語與我們的服務協議具有相同的含義。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">1、註冊</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">創建賬戶時，需要您提供數據，包括您的電子郵箱，電話，密碼或其他信息。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">如果您拒絕提供上述信息，我們將無法向您提供服務。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">2、日誌信息</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">會在您訪問我們的網站和登錄您的帳戶時收集您的瀏覽器發送的信息（“日誌數據”）。此日誌數據可能包括以下信息：您的計算機的互聯網協議（“IP”）地址、瀏覽器類型、瀏覽器版本、您訪問的服務的頁面、訪問時間和日期、在這些頁面上花費的時間和其他統計數據。此外，我們可能會使用Google Analytics等第三方服務來收集、監控和分析此類信息，以提升我們服務的功能性。這些第三方服務提供商有自己的隱私政策來說明他們如何使用此類信息。這些第三方服務提供商只能在代表我們執行任務所需時查看使用您的個人數據。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">3、我們如何使用您的信息</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們可能會使用您提供的個人信息用於下列目的：</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（1）向您展示我們的網站及其內容;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（2）將您識別為我們系統中的用戶;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（3）處理您的訂單;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（4）提供客戶服務;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（5）回應您的要求;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（6）為您提供產品更新;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（7）改善我們的網站;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（8）發送與我們產品相關的新聞通訊，調查，優惠和其他宣傳材料;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（9）與您溝通;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（10）管理風險並保護本網站，我們的服務和您免受欺詐;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（11）遵守所有適用的法律和法規，並執行本網站和</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">服務協議;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">（12）其他徵得您同意的目的。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">4、關於Cookie的信息</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">為了使您獲得更好的網站訪問體驗，我們使用“Cookie”來允許本網站識別您的瀏覽器並存儲用戶偏好和其他信息。如果您的瀏覽器或瀏覽器附加服務允許，您可修改對Cookie的接受程度或拒絕我們的Cookie。有關詳情，請參見AboutCookies.org。但如果您這麼做，在某些情況下可能會影響您訪問我們網站的體驗或無法使用我們部分的服務。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">二、第三方服務提供商</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們可能會聘請第三方公司為我提供業務相關服務。這些第三方只能在為我們提供服務的期間訪問您的個人數據，並且有義務不得透露或將其用於任何其他目的。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">三、信息安全</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">雖然我們致力於保護我們的網站和服務，但您有責任保護和維護您的個人信息安全，並驗證我們維護的有關您的個人信息是否準確和最新。您必須防止未經授權訪問您的帳戶。使用共享計算機時請務必註銷，並且不要向未經授權的人員披露您的帳戶密碼和任何其他帳戶註冊信息。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">然而，沒有一個通過因特網傳輸的方法或電子存儲方法是100％安全的。因此，在我們盡力保護您的個人信息的同時，我們無法保證其絕對的安全性。我們無法保證您傳輸到我們網站和/或通過我們的服務的個人信息的安全性。任何個人信息的傳輸均由您自行承擔風險。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">四、國際數據傳輸</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">通過提交您的信息，您同意您的信息（包括個人數據）可能會傳輸和保存到您所在國家/地區管轄範圍之外的計算機和系統。若發生該等傳輸，我們會盡力確保您的信息得到和您所在國家/地區規定的程度基本一致的保護。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">五、第三方鏈接</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們的服務可能包含鏈接指向非我們運營的第三方網站。如果您點擊第三方鏈接，您將被引導至該第三方網站。本隱私政策不適用於其他第三方網站。我們強烈建議您查看所訪問的網站的隱私政策。 </span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">無法管控任何第三方網站或服務的內容和隱私政策，也不承擔任何責任。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">六、兒童隱私</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們不會故意從13歲以下的人士收集或維護我們網站上的個人數據，並且我們網站的任何部分都不會針對13歲以下的人士。如果您未滿13歲，請不要以任何方式使用或訪問本網站。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">當我們得知我們無意中收集了13歲以下的人士的個人數據時，我們會根據適用法律法規進行刪除或採取其他合適的措施。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">七、個人數據使用目的變更</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">如果我們決定將您的個人數據用於本隱私政策聲明以外的任何其他目的，我們將進行通知並為您提供一個有效的退出途徑，供您選擇不讓自己的個人數據用於本隱私政策規定外的其他目的。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">我們可能會向您發送有關新服務和新活動的電子郵件。您可以在我們發送的每封電子郵件中選擇“取消訂閱”功能來拒絕接收</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">的宣傳性電子郵件。但是，您同意使用服務的用戶應繼續接收與服務相關的其他重要電子郵件。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">八、信息保存時間</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">除非適用法律法規有存留要求，我們只會在達成本政策所述目的所需的期限內保留和存儲您的個人信息。在法律允許的情況下，我們可能會存儲一定的個人信息用於備案或通過法律途徑保障自身的權益。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">九、如何了解，獲取，更改或刪除您的個人信息</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">如果您想確認我們正在處理您的數據，訪問我們可能擁有的有關您的個人數據，更正我們收集的您的個人信息，或刪除我們收集的您的個人信息，您可以使用本隱私政策規定的聯繫方式與我們聯繫。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">對我們而言，確保我們收集的有關您的個人信息的準確性，完整性和最新性非常重要。如果您發現您的信息不准確或已過時，請告知我們，我們會進行相應的更改。但是，如果我們認為更改會違反任何法律或法律要求，或導致信息不正確，我們可能無法接受更改信息的請求。</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">十、如何联系我們</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">如果您對本隱私政策有任何疑問，請</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">打开网站首页</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">，</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">找到社交媒体</span><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">與我們聯繫</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-transform:none">&nbsp;</span>
  </p>
  <p style="font-size:15pt; line-height:115%; margin:0pt"><span
      style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-transform:none">&nbsp;</span>
  </p>
  </div>
</template>

<script>
export default {
  name: "yinsi_zh"
}
</script>

<style scoped>

</style>
