<template>
  <el-container style="min-height: 600px">
    <div class="container">
      <h2 class="title">{{$t('text159')}}</h2>

      <h2 class="arcicleTitle">{{detail.title}}</h2>
      <span style="font-size: 12px;color: #797979;padding-top: 10px">{{formatDate2(new Date(detail.timeLong))}}</span>

      <div v-html="detail.content" style="margin-top: 30px"></div>

    </div>
  </el-container>
</template>

<script>
import {formatDate} from "../../utils/commonUtil";

export default {
  name: "NoticeDetail",
  data(){
    return {
      detail:{}
    }
  },
  mounted() {
    const content = decodeURI(this.$route.query.data);
    const detail = JSON.parse(content);
    this.detail = detail;
  },
  methods:{
    formatDate2(time){
      return formatDate(time,'yyyy-MM-dd hh:mm:ss');
    }
  }
}
</script>

<style scoped>
.container{
  width: 1200px;
  margin: 0 auto;
}
.title{
  font-size: 24px;
  color: #000;
  margin:30px 0 20px 0;
}
.arcicleTitle{
  font-size: 32px;
  color: #333333;
}
</style>
