<template>
  <transition name="v-dialog-animation">
    <div
      v-if="value"
      v-mask="isMask"
      class="v-dialog"
      :style="{ zIndex,...dialogStyle }"
      :class="{'has-header': $slots.header || title}"
    >
      <div class="v-dialog__close-icon" @click.stop="closed">
        <svg width="14px" height="14px" viewBox="0 0 14 14">
          <path d="M0,0 L14,14" stroke-width="2" />
          <path d="M14,0 L0,14" stroke-width="2" />
        </svg>
      </div>

      <div v-if="$slots.header || title" class="v-dialog__header">
        <slot name="header">
          {{ title }}
        </slot>
      </div>

      <div class="v-dialog__content">
        <slot />
      </div>

      <div v-if="$slots.footer" class="v-dialog__footer">
        <slot name="footer" />
      </div>
    </div>
  </transition>
</template>

<script>
/**
 * @content 弹框组件
 * @slot header  头部内容
 * @slot default 内容部分
 * @slot footer 尾部内容
 * @props isMask 是否显示遮罩层
 * @props value 显示隐藏绑定的值,可以用 v-model 绑定
 * @props appendBody 是否把弹框组件挂在在 body 下
 * @event change v-model 绑定事件
 */
import store from '../store';

export default {
  name: 'VDialog',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '',
    },
    // 是否显示遮罩层
    isMask: {
      type: Boolean,
      default: true,
    },
    // 是否显示 v-model 绑定值
    value: {
      type: Boolean,
      default: false,
    },
    // 是否挂在在 body 下
    appendBody: {
      type: Boolean,
      default: true,
    },
    // 样式
    // eslint-disable-next-line vue/require-default-prop
    dialogStyle: Object,
  },
  data() {
    return {
      // index 值
      zIndex: store.globalDialogIndex + 2,
      // mask dom 元素
      maskDom: null,
    };
  },
  mounted() {
    if (this.appendBody) {
      document.body.appendChild(this.$el);
    }
    store.globalDialogIndex = this.zIndex;
  },
  destroyed() {
    if (this.appendBody && this.$el.parentNode) {
      document.body.removeChild(this.$el);
    }
    this.$destroy();
  },
  methods: {
    // 关闭事件
    closed() {
      this.$emit('change', false);
    },
  },
};
</script>

<style>
.v-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  background-color: #fff;
  color: #303133;
  font-size: 14px;
  border-radius: 4px;
}
.v-dialog__close-icon {
  padding: 5px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: all 0.3s;
  line-height: 0.4;
  z-index: 2;
  color: #ccc;
}
.v-dialog__close-icon path{
  stroke: currentColor;
}
.v-dialog__close-icon:hover {
  transform: rotate(90deg);
  color: #333;
}
.v-dialog__footer {
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  padding: 30px;
  padding-top: 0;
}
.v-dialog__header {
  padding: 0 30px;
  position: relative;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
}
.v-dialog__content {
  padding: 30px;
}
.has-header .v-dialog__content {
  padding-top: 0;
}

.v-dialog-animation-enter,
.v-dialog-animation-leave-to {
  transform: translate(-50%, calc(-50% - 30px));
  opacity: 0;
}

.v-dialog-animation-enter-active,
.v-dialog-animation-leave-active {
  transition: all 0.1s;
}
</style>
