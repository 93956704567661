import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import Routes from "./router/routes";
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n';
import local from "./lan/local";
// import axios from 'axios'
// import VueAxios from 'vue-axios'
import http from './api/axios'

import 'element-ui/lib/theme-chalk/index.css';
import './element-variables.scss'
import {getCookie, setCookie} from "./utils/cookieUtil";
//防多次点击，重复提交
import preventReClick from './utils/plugins.js';
import Component from './components/Public';
import store from './store/index';
import VueDOMPurifyHTML from 'vue-dompurify-html'
import 'github-markdown-css/github-markdown.css'
import {deviceType} from './utils/deviceType';
const userAgent = navigator.userAgent;
const device = deviceType(userAgent);
if (device.type !== 'pc') {
  // if (process.env.NODE_ENV !== 'development') {
    // window.location.href = 'https://m.originstorage.cc';
    const currentHost = window.location.host;
    const array = currentHost.split(",")
    if(array.length >= 2)
      window.location.href = "https://m." + array[array.length - 2] +'.' + array[array.length - 1];
  // }
}
console.log(Vue.config);
Vue.config.productionTip = false
Vue.use(Router)
Vue.use(ElementUI)
Vue.use(VueI18n)


Vue.use(VueDOMPurifyHTML)

// Vue.use(VueAxios, axios)
Vue.use(Component)
Vue.prototype.$http = http;
if(process.env.NODE_ENV !== 'development') {
  const host = location.host.split('.');
  Vue.prototype.baseUrlHost = `https://compute.${host[1]}.${host[2]}`;
}else {
  Vue.prototype.baseUrlHost = "http://compute.cacheily.com";
}

Vue.prototype.baseUrlHost2 = "https://www.kaiyuanipfs.com";

const currentLan = getCookie("lan") ? getCookie("lan") : 'el_GR';
setCookie("lan",currentLan);
// 通过选项创建 VueI18n 实例
const i18n = new VueI18n({
  locale: currentLan, // 设置地区
  messages:local, // 设置地区信息
})


const router = new Router({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  mode: 'history',
  routes:Routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  document.documentElement.scrollTop=0;
  next();
})

new Vue({
  i18n,
  preventReClick,
  render: h => h(App),
  router,
  store,
}).$mount('#app')
