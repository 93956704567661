import index from "@views/index";

import home from "@views/index/home";
import yunsuanli from "@views/index/yunsuanli";
import kuangji from "@views/index/kuangji";
import kuangjidetail from "@views/index/kuangjidetail";
import suanlidetail from "@views/index/suanlidetail";
import yinsi_en from "@views/yinsi_en";
import yinsi_zh from "@views/yinsi_zh";
import messageSucceeded from "@views/messageSucceeded";
import aboutUs from "@views/index/aboutus";
import NoticeDetail from "@views/index/NoticeDetail";
import myOrder from "@views/index/myOrder";
import demo from "@views/demo";
import AppDownload from "@views/index/appDownload";
const routes = [
    {
        path: '/',
        component: index,
        redirect:"/index/home"
    },
    {
        path: '/index',
        component: index,
        children:[
            {
                path:'/index/home',
                component:home
            },{
                path:'/index/cloudComputingPower',
                component:yunsuanli
            },{
                path:'/index/miningMachine',
                component:kuangji
            },{
                path:'/index/miningMachine_detail',
                component:kuangjidetail
            },{
                path:'/index/cloudComputingPower_detail/:id',
                component:suanlidetail,

            },{
                path:'/index/aboutUs',
                component:aboutUs,

            },{
                path: '/index/messageSucceeded',
                component: messageSucceeded,
            },{
                path: '/index/en_US/privacyAgreement',
                component: yinsi_en,
            },{
                path: '/index/el_GR/privacyAgreement',
                component: yinsi_zh,
            },{
                path: '/index/noticeDetail',
                component: NoticeDetail,
            }, {
                path: '/index/myOrder',
                component: myOrder,
            }, {
                path: '/index/AppDownload',
                component: AppDownload,
            }
        ]
    },
    {
        path: '/demo',
        component: demo,
    },
    {
        path: '*',
        component: index,
        redirect:"/index/home"
    },


]

export default routes;
