<template>
  <div style="padding:20px">
    <h1 style="font-size:32pt; line-height:115%; margin:0pt 0pt 7.05pt;text-align: center"><span
        style="color:#333333; font-family:-apple-system; font-size:32pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">Privacy Agreement</span>
    </h1>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We respect the privacy of our users and website visitors (collectively, "you") and are committed to protecting your online privacy. This Privacy Policy outlines the process by which </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Storage</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(hereafter referred to as "</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">" or "we") manage, collect, maintain and process user personal data.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We may update this Privacy Policy from time to time. We will post a new privacy policy on this page to notify you of any changes. We encourage you to periodically review this privacy policy to understand the changes. Changes to this Privacy Policy are effective as of the time they are posted on this page.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">I Information collection</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We may use your personal data to provide and improve the mining machine sharing services ("Services") that </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> provides to you and to improve your experience on this website. "Personal Data" means any information relating to a person identified or identifiable. When you create an account for using the service, we collect the information you provide to us. We also use a variety of techniques to collect and store information, including cookies, pixel tags, local storage (such as browser network storage or application data cache, database and server logs).</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">By using the Service or this Site, you consent to the collection and use of information in accordance with this Privacy Policy. Unless otherwise stated in this Privacy Policy, the terms used in this Privacy Policy have the same meaning as our Service Agreement.</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
      <li style="font-family:Calibri; font-size:12pt; line-height:115%; list-style-position:inside; margin:0pt 0pt 0pt 20pt; text-align:justify; text-indent:-18pt">
        <span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
          style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-decoration:none; text-transform:none">Registration</span>
      </li>
    </ol>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">When you create an account, you will need to provide data, including your email address, phone number, password or other information.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">If you refuse to provide the above information, we will not be able to provide you with the service.</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
      <li style="font-family:Calibri; font-size:12pt; line-height:115%; list-style-position:inside; margin:0pt 0pt 0pt 20pt; text-align:justify; text-indent:-18pt">
        <span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
          style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-decoration:none; text-transform:none">Payment information</span>
      </li>
    </ol>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Your payment information will be sent to our third party payment processor. We store your encrypted wallet address. We can access and retain user information through our third party payment processor.</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
      <li style="font-family:Calibri; font-size:12pt; line-height:115%; list-style-position:inside; margin:0pt 0pt 0pt 20pt; text-align:justify; text-indent:-18pt">
        <span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
          style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-decoration:none; text-transform:none">Log information</span>
      </li>
    </ol>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> collects information ("log data") sent by your browser when you visit our website and log into your account. This log data may include the following information: your computer's Internet Protocol ("IP") address, browser type, browser version, page of services you visit, access time and date, time spent on these pages, and more Statistical data. In addition, we may use third-party services such as Google Analytics to collect, monitor, and analyze such information to enhance the functionality of our services. These third-party service providers have their own privacy policies that explain how they use this information. These third-party service providers can only view the use of your personal data when needed to perform tasks on our behalf.</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
      <li style="font-family:Calibri; font-size:12pt; line-height:115%; list-style-position:inside; margin:0pt 0pt 0pt 20pt; text-align:justify; text-indent:-18pt">
        <span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
          style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-decoration:none; text-transform:none">How we use your information</span>
      </li>
    </ol>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We may use the personal information you provide for the following purposes:</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(1) Show you our website and its content;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(2) Identify you as a user in our system;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(3) Process your order;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(4) Provide customer service;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(5) Respond to your request;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(6) Provide you with product updates;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(7) Improve our website;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(8) Send newsletters, surveys, offers and other promotional materials related to our products;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(9) Communicate with you;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(10) Manage risk and protect this website, our services and you from fraud;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(11) Comply with all applicable laws and regulations and enforce this website and the </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> Service Agreement;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">(12) Other purposes for your consent.</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
      <li style="font-family:Calibri; font-size:12pt; line-height:115%; list-style-position:inside; margin:0pt 0pt 0pt 20pt; text-align:justify; text-indent:-18pt">
        <span style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
          style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-decoration:none; text-transform:none">Information about cookies</span>
      </li>
    </ol>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">In order to give you a better website access experience, we use "cookies" to allow this website to identify your browser and store user preferences and other information. You may modify the acceptance of cookies or reject our cookies if your browser or browser additional services allow it. For more information, see AboutCookies.org. However, if you do this, in some cases it may affect your experience of visiting our website or the inability to use some of our services.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">II Third-party service provider</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We may hire a third-party company to provide business-related services to me. These third parties may only access your personal data while providing services to us and are obliged not to disclose or use them for any other purpose. Additionally, to improve user experience, we offer single sign-on solutions for account login and these third parties (including Facebook and Google) may receive information from these services when you elect to use them. When </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> shares your personal information and other collected information with third-party service providers, we require that they use your information only for the purpose of providing services to us and consistent with this privacy policy.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">III If you are a resident of the European Economic Area (EEA)</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">If you are a resident of the European Economic Area (EEA), you have certain data protection rights in accordance with applicable laws and regulations. Our goal is to take reasonable steps to allow you to correct, modify, delete or limit the use of your personal data. If you would like to know about the personal data we hold about you and you wish to remove your personal information from our system, please contact us.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">IV Information security</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">While we are committed to protecting our websites and services, you are responsible for protecting and maintaining the security of your personal information and verifying that the personal information we maintain about you is accurate and up-to-date. You must prevent unauthorized access to your account. Be sure to log out when using a shared computer and do not disclose your account password and any other account registration information to unauthorized people.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">However, none of the methods or electronic storage methods transmitted over the Internet are 100% secure. Therefore, while we strive to protect your personal information, we cannot guarantee its absolute security. We cannot guarantee the security of your personal information transmitted to our website and/or through our services. Any personal information transmitted at your own risk.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">V International data transmission</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">By submitting your information, you agree that your information, including personal data, may be transferred and stored to computers and systems outside of the jurisdiction of your country. In the event of such transmissions, we will endeavour to ensure that your information is protected to the same extent as is specified in your country.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">VI Third party links</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Our services may include links to third party websites that are not operated by us. If you click on a third party link, you will be directed to the third party website. This Privacy Policy does not apply to other third party websites. We strongly encourage you to review the privacy policy of the websites you visit. </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> cannot control the content and privacy policies of any third party website or service and is not responsible for it.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">VII Children's privacy</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We do not knowingly collect or maintain personal data on our website from people under the age of 13, and no part of our website will target individuals under the age of 13. If you are under the age of 13, please do not use or access this website in any way.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">When we learn that we have inadvertently collected personal data from individuals under the age of 13, we will remove or take other appropriate measures in accordance with applicable laws and regulations.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">VIII Change of personal data usage purpose</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">If we decide to use your personal data for any purpose other than this Privacy Policy Statement, we will notify you and provide you with a valid exit path for you to choose not to use your personal data outside of this Privacy Policy. Other purposes.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">We may send you emails about new services and new events. You can refuse to receive promotional emails from </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Origin</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> by selecting the "Unsubscribe" feature in each email we send. However, you agree that users of the Service should continue to receive other important emails related to the Service.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">IX Information retention time</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">Unless there is a retention requirement in applicable laws and regulations, we will only retain and store your personal information for the period of time required to achieve the purposes stated in this policy. Where permitted by law, we may store certain personal information for filing or legal protection of our rights.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">X How to understand, acquire, change or delete your personal information</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">If you wish to confirm that we are processing your data, accessing any personal data we may have about you, correcting any personal information we collect, or deleting your personal information we collect, you may use the contact details set forth in this Privacy Policy. Ways to contact us.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">It is important for us to ensure that the accuracy, completeness and up-to-dateness of the personal information we collect about you is important. If you find that your information is inaccurate or out of date, please let us know and we will make the appropriate changes. However, we may not be able to accept requests to change information if we believe that the change will violate any legal or legal requirements or result in incorrect information.</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">XI How to contact us</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">If you have any questions about this Privacy Policy, please send an email </span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">from home page</span><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> .</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0"><span
        style="font-family:Calibri; font-size:10.5pt">&nbsp;</span></p>

  </div>
</template>

<script>
export default {
  name: "yinsi_en"
}
</script>

<style scoped>

</style>
