<template>
  <div class="demo">

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
      console.log('测试数据',this);
      this.$_toast.success('测试成功');
      this.$http({
          url: "common/public_info_v5",
          hostType: '/fe-ex-api/',
      }).then((res) => {
          console.log(res);
      })
  },
};
</script>