<template>
  <el-container class="home-containner">
    <el-main style="padding: 0">
      <!--banner-->
      <div class="banner">
        <el-carousel height="500px" style="width: 100%">
          <el-carousel-item v-for="(item, index) in banner" :key="index">
            <img
              :src="item.imageUrl"
              alt="no fount image"
              style="width: 100%; height: 100%; object-fit: cover"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 公告 -->
      <div class="notice-wrap">
        <div class="notice-container" v-if="gonggaoList.length > 0">
          <span class="notice-title_styl">
            {{ $t("text159") }}
          </span>
          <div class="notice-inner_wrap">
            <ul
              :class="{ marquee_top: animate }"
              @mouseenter="Stop()"
              @mouseleave="Up()"
            >
              <li
                v-for="(text, index) in gonggaoList"
                :key="index"
                @click="onNoticeDetails"
              >
                <span>{{ text.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--tab切换列表-->
      <div class="mainlist">
        <div class="tab">
          <div
            :class="{ childView: true, activeChildView: tab == 0 }"
            @click="tab = 0"
          >
            {{ $t("text19") }}
          </div>
          <div
            :class="{ childView: true, activeChildView: tab == 1 }"
            @click="tab = 1"
          >
            {{ $t("text20") }}
          </div>
          <div
            :class="{ childView: true, activeChildView: tab == 2 }"
            @click="tab = 2"
          >
            {{ $t("text21") }}
          </div>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            width: 1200px;
            margin: 0 auto;
            overflow: hidden;
          "
          v-show="tab == 0 || tab == 1"
          v-loading="loadingPower"
        >
          <div class="leftDec">
            <img
              src="@assets/ic_fil.png"
              alt=""
              style="width: 28px; height: 28px"
            />
            <h2
              style="
                margin: 10px 0;
                font-size: 20px;
                font-family: DINPro-Bold, DINPro;
                font-weight: bold;
                color: #333333;
              "
            >
              {{ $t("text20") }}
            </h2>
            <p class="dectitle">{{ $t("text27") }}</p>
            <p class="dectitle">{{ $t("text26") }}</p>
          </div>
          <div class="centerView" style="margin-top: 0">
            <div>
              <!--`hotTagVal` tinyint(3) DEFAULT '1' COMMENT '是否推荐位  1：热门推荐  2: 新手体验 3: 全选',-->
              <div
                 style="height: 392px"
                class="itemView"
                v-for="(item, index) in list"
                :key="index"
                @click="hashRateDetails(item)"
              >
                <div v-if="!item.isReserved">
                  <div :class="['tag', { ['status' + item.productStatus]: true }]">
                    {{ $t("text200." + item.productStatus) }}
                  </div>

                  <img
                    :src="item.thumbnail2List ? item.thumbnail2List :require('@assets/ic_suanli.png')"
                    style="width: 222px; height: 126px; margin:15px;"
                  />
                  <h2
                    style="
                      margin:0 15px 20px 15px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      text-align:left
                    "
                  >
                    {{
                      item.title }}
                  </h2>
                  <div style="display: flex; margin: 10px 15px">
                    <span class="labelfont" style="flex: 1">{{
                      $t("text47")
                    }}</span>
                    <span style="flex: 1; text-align: end" class="labelval"
                      >{{ item.miningCycle }}{{ $t("text181") }}</span
                    >
                  </div>
                  <div style="display: flex; margin: 10px 15px">
                    <span class="labelfont" style="flex: 1">{{
                      $t("text23")
                    }}</span>
                    <span style="flex: 1; text-align: end" class="labelval"
                      >{{ item.price }}
                      {{ item.paySymbol | filterSymbol }}/T</span
                    >
                  </div>
                  <div style="display: flex; margin: 10px 15px">
                    <span class="labelfont" style="flex: 1">{{
                      $t("text24")
                    }}</span>
                    <span style="flex: 1; text-align: end" class="labelval"
                      >{{ item.profitRatio }}%</span
                    >
                  </div>
                  <el-button
                    size="lg"
                    v-text="$t('text25')"
                    type="primary"
                    :class="['btn', { disableByn: item.productStatus == 2 }]"
                    @click.stop="
                      $router.push({
                        path: '/index/cloudComputingPower_detail/' + item.productId,
                      })
                    "
                    :disabled="item.productStatus == 2"
                  />
                </div>
                <div v-else class="product-Reserved">
                  <div class="occupy-position">
                    <span>{{ $t('filClould') }}</span>
                  </div>
                  <el-button
                    class="btn disableByn"
                    size="lg"
                    type="primary"
                    :disabled="true"
                  >
                    {{$t('stayTuned')}}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            width: 1200px;
            margin: 0 auto;
            overflow: hidden;
          "
          v-show="tab == 0 || tab == 2"
          v-loading="loadingMiner"
        >
          <div class="leftDec">
            <img
              src="@assets/ic_fil.png"
              alt=""
              style="width: 28px; height: 28px"
            />
            <h2
              style="
                margin: 10px 0;
                font-size: 20px;
                font-family: DINPro-Bold, DINPro;
                font-weight: bold;
                color: #333333;
              "
            >
              {{ $t("text21") }}
            </h2>
            <p class="dectitle">{{ $t("text28") }}</p>
            <p class="dectitle">{{ $t("text29") }}</p>
          </div>

          <div class="centerView" style="margin-top: 0">
            <div>
              <div
                class="itemView"
                style="height:392px"
                v-for="(item, index) in staticData"
                :key="index"
                @click="poolDetails(item)"
              >
                <div v-if="!item.isReserved">
                  <img
                    :src="item.thumbnail2List ? item.thumbnail2List : require('@assets/pic_kuangjituoguan.png')"
                    style="width: 222px; height: 126px; margin:15px;"
                  />
                  <h2
                    style="
                      margin:0 15px 20px 15px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      text-align:left
                    "
                  >
                    {{ item.title || '--' }}
                  </h2>

                  <div
                    v-for="(text, index) in item.otherAttrs && item.otherAttrs.slice(0, 2)"
                    :key="index"
                    style="display: flex; margin: 10px 15px"
                    >
                    <span class="labelfont">
                      {{text.attr}}
                    </span>
                    <span style="flex: 1; text-align: end" class="labelval">{{
                      text.description
                    }}</span>
                  </div>
                  <div style="display: flex; margin: 10px 15px">
                    <span class="labelfont" >{{
                      $t("text24")
                    }}</span>
                    <span style="flex: 1; text-align: end" class="labelval">{{
                      item.profitRatio
                    }}</span>
                  </div>
                  <el-button
                    size="lg"
                    v-text="$t('text25')"
                    type="primary"
                    class="btn"
                    @click.stop="
                      $router.push({
                        path: '/index/miningMachine_detail',
                        query: {
                         productId: item.productId,
                        },
                      })
                    "
                  />
                </div>
                <div v-else class="product-Reserved">
                  <div class="occupy-position">
                     <span>{{ $t('pollDes') }}</span>
                  </div>
                  <el-button
                    class="btn disableByn"
                    size="lg"
                    type="primary"
                    :disabled="true"
                  >
                    {{ $t('stayTuned') }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--參與流程-->
      <el-row style="height: 436px; background: #fff; padding-bottom: 20px">
        <el-col :span="24">
          <h2 style="text-align: center; margin-top: 47px">
            {{ $t("liucheng") }}
          </h2>
        </el-col>

        <div class="container">
          <el-row>
            <el-col :span="8">
              <div class="blockView">
                <img src="@assets/ic_zhuce.png" class="decicon" />
                <p>{{ $t("register") }}</p>
                <span
                  >{{ $t("text1") }} <br />
                  {{ $t("text2") }}</span
                >
              </div>
            </el-col>
            <el-col :span="8">
              <div class="blockView">
                <img src="@assets/ic_goumai.png" class="decicon" />
                <p>{{ $t("text3") }}</p>
                <span>{{ getWebsiteConfigCache('title') }}{{ $t("text5") }}</span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="blockView">
                <img src="@assets/ic_shouyi.png" class="decicon" />
                <p>{{ $t("text6") }}</p>
                <span
                  >{{ $t("text7") }} <br />
                  {{ $t("text8") }}</span
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </el-row>

      <!--      服務優勢-->
      <el-row style="height: 391px; background: #f7f9fc; margin-bottom: 60px">
        <el-col :span="24">
          <h2 style="text-align: center; margin-top: 47px">
            {{ $t("text9") }}
          </h2>
        </el-col>

        <div class="container">
          <el-row>
            <el-col :span="6">
              <div class="blockView">
                <img src="@assets/ic_caigou.png" class="decicon" />
                <p>{{ $t("text10") }}</p>
                <span>{{ $t("text11") }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="blockView">
                <img src="@assets/ic_tuoguan.png" class="decicon" />
                <p>{{ $t("text12") }}</p>
                <span>{{ $t("text13") }}</span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="blockView">
                <img src="@assets/ic_hegui.png" class="decicon" />
                <p>{{ $t("text14") }}</p>
                <span>{{ $t("text15") }}</span>
              </div>
            </el-col>

            <el-col :span="6">
              <div class="blockView">
                <img src="@assets/ic_tixian.png" class="decicon" />
                <p>{{ $t("text16") }}</p>
                <span>{{ $t("text17") }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-row>

      <!--    合作伙伴  临时注释googel 推广设计侵权 后续可直接放开-->
      <!--
      <el-row style="height: 440px;background: #fff;">
        <div>
          <h2 style="text-align: center;margin-top: 47px">{{ $t('text18') }}</h2>
        </div>

        <div class="centerView" id="hezuohuoban" >
          <div style="display: flex;align-items: center;margin-top: 20px">
            <img src="@assets/logo_press_nvidia.png" class="icon" alt="logo_nivdia"/>

            <img src="@assets/logo_press_langchao.png" class="icon" alt="logo_press_langchao"/>
            <img src="@assets/logo_press_bb.png" class="icon" alt="logo_press_bb"/>
            <img src="@assets/logo_press_chainup.png" class="icon" alt="logo_press_chainup"/>
            <img src="@assets/logo_press_jiedianziben.png" class="icon" alt="logo_press_jiedianziben"/>
          </div>

          <div style="display: flex;align-items: center;margin-top: 20px">

            <img src="@assets/logo_press_huobi.png" class="icon" alt="@assets/logo_press_huobi.png"/>

            <img src="@assets/logo_press_ali.png" class="icon" alt="@assets/logo_press_ali.png"/>

            <img src="@assets/logo_press_jinse.png" class="icon" alt="@assets/logo_press_jinse.png"/>

            <img src="@assets/logo_press_tv.png" class="icon" alt="@assets/logo_press_tv.png"/>
            <img src="@assets/logo_press_jiedianziben2.png" class="icon" alt="@assets/logo_press_jiedianziben2.png"/>
          </div>
        </div>
      </el-row>
-->
    </el-main>
  </el-container>
</template>

<script>
// import notice from "../notice";

export default {
  name: "home",
  mounted() {
    this.getKuangji();

    this.getsuanli();

    this.getBanner();

    this.getGonggao();
  },
  filters: {
    filterSymbol(item) {
      if (!item) {
        return item;
      }
      return item.toLowerCase() == "usdt" ? "$" : item;
    },
  },
  // components: {notice},
  watch: {
    tab: function () {
      switch (this.tab) {
        case 0:
          this.getsuanli(true);
          break;
        case 1:
          this.getsuanli(false);
          break;
        case 2:
          break;
        default:
          return null;
      }
    },
  },
  data() {
    return {
      loadingPower: true,
      loadingMiner: true,
      staticData: [],
      list: [],
      tab: 0,
      banner: [],
      gonggaoList: [],
      currentIndex: 0,
      animate: false,
    };
  },
  methods: {
    getWebsiteConfigCache(attr) {
      const config = this.$store.state.market.websiteConfig;
      // console.log('state.data getWebsiteConfigCache:', config, attr)
      if (null != config && config[attr]) {
        return config[attr];
      } else {
        return attr;
      }
    },
    showMarquee: function () {
      //字体滚动
      this.animate = true;
      setTimeout(() => {
        this.gonggaoList.push(this.gonggaoList[0]);
        this.gonggaoList.shift();
        this.animate = false;
      }, 500);
    },
    //鼠标移上去停止
    Stop() {
      clearInterval(this.timer);
    },
    Up() {
      this.timer = setInterval(this.showMarquee, 1500);
    },
    // 获取算力数据
    async getsuanli() {
      const result = await this.$http({
        url: "/project_sku",
        hostType: "/fe-origin-hashrate-api",
        params: {
          page: 1,
          pageSize: 3,
          showTag: 2,
          type: 1,
        },
      });
      const { code, data } = result;

      this.loadingPower = false
      if (code == "0") {
        const newData = data.data || [];
        console.log(newData, 'new');
        let arr = [];
        if(newData.length < 4) {
          for (let i = 0; i < 4 - newData.length; i++) {
            arr.push({
              isReserved: true, // 是否是预留位置
            });
          }
        }
        console.log(arr);
        this.list = newData.concat(arr);
        console.log(this.list);
      }
    },
    async getBanner() {
      const result = await this.$http({
        url: "/common/index",
      });
      const { code, data } = result;
      if (code == "0") {
        this.banner = data.cmsAdvertList;
      }
    },

    async getKuangji() {
      let list = await this.$http({
        url: "/project_sku",
        hostType: "/fe-origin-hashrate-api",
        params: {
          type: 2,
          showTag: 2, //pc
          page: 1,
          pageSize: 4,
          attrKvKey: "origin_product_list_attr",
        },
      });
      let { data } = list;
      let arr = [];

      this.loadingMiner = false
      if(!data.data) {
        console.log('232232323')
        for (let i = 0; i < 4; i++) {
            arr.push({
              isReserved: true, // 是否是预留位置
            });
          }
      } else {
        if (data.data.length < 4) {
          for (let i = 0; i < 4 - data.data.length; i++) {
            arr.push({
              isReserved: true, // 是否是预留位置
            });
          }
        }
      }
      const newData = data.data || [];
      // newData.forEach(el => {
      //   if (el.otherAttrs.length) {
      //     el.otherAttrs.forEach((ele) => {
      //       if(ele.attr === '')
      //     })
      //   }
      // });
      this.staticData = newData.concat(arr);
    },
    async getGonggao() {
      const result = await this.$http({
        url: "/notice/notice_info_list",
        params: {
          keyword: "",
          page: 1,
          pageSize: 20,
        },
      });
      const { code, data } = result;
      if (code == "0") {
        this.gonggaoList = data.noticeInfoList;
        this.timer = setInterval(this.showMarquee, 1500);
        setInterval(() => {
          if (this.currentIndex >= this.gonggaoList.length) {
            this.currentIndex = 0;
          } else {
            this.currentIndex++;
          }
        }, 5000);
      }
    },

    onNoticeDetails() {
      this.$router.push({
        path: "/index/noticeDetail",
        query: {
          data: encodeURI(JSON.stringify(this.gonggaoList[this.currentIndex])),
        },
      });
    },
    // 矿机查看详情
    poolDetails(item) {
      if(!item.productId) return;
      this.$router.push({
        path: '/index/miningMachine_detail',
        query: {
          productId: item.productId
        },
      })
    },
    // 算力跳转
    hashRateDetails(item) {
      if(item.productStatus != 2 && !item.isReserved) {
        this.$router.push({
          path: '/index/cloudComputingPower_detail/' + item.productId,
        })
      }
    }
  },
};
</script>

<style scoped>
.banner {
  height: 500px;
  background: #272727;
  overflow: hidden;
  width: 100%;
}

.mainlist {
  width: 100%;
  background: #f7f9fc;
  margin-bottom: 50px;
}

.mainlist .tab {
  cursor: default;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.decicon {
  width: 100px;
  height: 88px;
}

.blockView {
  margin-top: 50px;
  text-align: center;
}

.blockView p {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
  margin: 20px 0 9px 0;
}

.blockView span {
  font-size: 14px;
  color: #000000;
}

.tab {
  /*width: 900px;*/
  width: 100%;
  height: 32px;
  overflow: hidden;
  /*margin:0 auto;*/
  margin-top: 50px;
  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.childView {
  /*width: 120px;*/
  padding: 0 24px;
  height: 32px;
  background: #fff;
  color: #797979;
  text-align: center;
  line-height: 32px;
  border-radius: 50px;
  margin-left: 30px;
}

.activeChildView {
  background: #3e26d1;
  color: #fff;
}

.itemView {
  width: 252px;
  min-height: 342px;
  background: #fff;
  text-align: center;
  /* padding-bottom: 30px; */
  float: left;
  margin-left: 15px;
  margin-bottom: 30px;
  margin-top: 20px;
  position: relative;
  transition: 0.4s;
}
.itemView:hover {
  box-shadow: 0px 5px 10px 5px #eff2f7;
  transform: scale(1.05);
}
.occupy-position {
  height: 300px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.labelfont {
  font-size: 12px;
  color: #999999;
  text-align: left;
}

.labelval {
  font-size: 14px;
  color: #333333;
}

.btn {
  width: 222px;
  height: 44px;
  display: block;
  margin: 29px 15px;
  margin-bottom: 0;
  background: #f6f6f6;
  color: #333333;
  border: 0;
}

.btn:hover {
  background: #3e26d1;
}

.dectitle {
  color: #999999;
  font-size: 14px;
}

.leftDec {
  width: 112px;
  float: left;
  /* padding-left: 20px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.centerView {
  width: 1200px;
  margin: 0 auto;
  margin-top: 30px;
}

.icon {
  height: 100px;
  flex: 1;
  margin: 0 15px;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.icon:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.tag {
  width: 64px;
  height: 24px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.status0 {
  /*预售中*/
  background: url("~@/assets/yushouzhong.png") no-repeat center;
}
.status1 {
  /*出售中*/
  background: url("~@/assets/chushouzhong.png") no-repeat center;
}
.status2 {
  /*已售罄*/
  background: #f6f6f6; /*Old gradient for webkit*/
  color: #999;
}

#hezuohuoban img {
  width: 228px;
  height: 100px;
  margin-left: 0px;
  margin-right: 15px;
}

.disableByn {
  background: #f6f6f6 !important;
  color: #999 !important;
}
.product-Reserved {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #999999;
  /* position: relative; */
}
.product-Reserved .disableByn {
  position: absolute;
  bottom: 30px;
}
.product-Reserved span {
  margin: 0 65px;
}
</style>
<style>
.notice-wrap {
  background: #eef3fa;
  width: 100%;
}
.notice-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  overflow: hidden;
}
.notice-title_styl {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3e26d1;
  line-height: 14px;
  padding: 3px 10px;
  border-radius: 10px;
  border: 1px solid #3e26d1;
  cursor: pointer;
}
.notice-inner_wrap {
  height: 22px;
  overflow: hidden;
  padding-left: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
ul,
li {
  list-style: none;
}
.notice-inner_wrap li span {
  width: 1000px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
/* .inner-container {
    animation: myMove 1.5s linear infinite;
    animation-fill-mode: forwards;
  } */
/*文字无缝滚动*/
/* @keyframes myMove {
    0% {
      transform: translateY(0) rotateY(0);
    }
    100% {
      transform: translateY(-22px) rotateY(20deg);
    }
  } */
</style>
<style>
.el-carousel__button {
  border-radius: 2px;
}
.home-containner .el-carousel__arrow i {
  font-size: 28px;
  font-weight: 600;
}
</style>
