<script>
import VButton from '@/components/Public/Button';
import VDialog from '@components/Public/Dialog';

export default {
  name: 'AlertBox',
  components: {
    VDialog,
    VButton,
  },
  props: {
    // 头部标题,支持 jsx
    title: {
      type: [String, Object],
      default: '',
    },
    //  是否需要取消按钮
    hasCancel: {
      type: Boolean,
      default: true,
    },
    // 确认按钮文本
    confirmText: {
      type: String,
      default: '确认',
    },
    // 取消按钮文本
    cancelText: {
      type: String,
      default: '取消',
    },
    // 确认按钮props
    confirmProps: {
      type: Object,
      default: () => ({}),
    },
    // 取消按钮props
    cancelProps: {
      type: Object,
      default: () => ({}),
    },
    // 是否需要多选框,在多选框存在的时候,多选框为 false 时,确认按钮为禁用
    hasCheckbox: {
      type: Boolean,
      default: false,
    },
    // checkbox 文本
    checkboxText: {
      type: String,
      default: '',
    },
    // 内容,支持 jsx
    content: {
      type: [String, Object],
      default: '',
    },
    // 背景色
    boxStyle: {
      type: Object,
      default: () => ({}),
    },
    // 确定事件
    confirm: {
      type: Function,
      default: () => {},
    },
    // 取消事件
    cancel: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      // 是否显示
      isShow: false,
      // checkbox 值
      checked: false,
    };
  },
  methods: {
    // 取消
    handleCancel() {
      this.cancel();
      this.$emit('cancel');
      this.close();
    },
    // 确认
    handleConfirm() {
      this.confirm();
      this.$emit('confirm', this.hasCheckbox ? this.checked : null);
      this.close();
    },
    handleCheckboxChange(value) {
      this.checked = value;
    },
    close() {
      this.isShow = false;
      this.$nextTick(() => {
        this.$destroy();
      });
    },
  },
  render() {
    return (
      <v-dialog
        value={this.isShow}
        class="v-alert"
        onChange={this.close}
        dialogStyle={this.boxStyle}
      >
        <div className="v-alert__title" slot="header">
          {this.title}
        </div>
        <div class="v-alert__content">
          {this.content}
          {this.hasCheckbox ? (
            <v-checkbox
              value={this.checked}
              onChange={this.handleCheckboxChange}
              class="checkbox"
            >
              {this.checkboxText}
            </v-checkbox>
          ) : null}
        </div>
        <div class="v-alert__footer" slot='footer'>
          {this.hasCancel ? (
            <v-button
              plain
              onClick={this.handleCancel}
              size="medium"
              {...this.cancelProps}
            >
              {this.cancelText}
            </v-button>
          ) : null}
          <v-button
            onClick={this.handleConfirm}
            disabled={this.hasCheckbox && !this.checked}
            type="primary"
            size="medium"
            {...this.confirmProps}
          >
            {this.confirmText}
          </v-button>
        </div>
      </v-dialog>
    );
  },
};
</script>

<style >
.v-alert__content {
  text-align: center;
}
.v-alert .v-dialog__header {
  height: 90px;
  line-height: 90px;
  display: flex;
  align-items: center;
}
.v-alert__footer {
  text-align: right;
}

</style>
