<template>
  <el-container direction="vertical">
    <div class="banner">
      <img src="@assets/banner_suanlishichang.png" alt="" style="width: 100%;">
      <div class="content-container">
        <h2>{{$t('text113')}}</h2>
        <p>{{$t('text114')}}</p>
        <p>{{$t('text115')}}</p>
      </div>
    </div>
    <!--  -->
    <div class="minging-content">
      <template v-if="!loadingMiner && list.length<=0">
        <el-empty style="height: 500px"></el-empty>
      </template>

      <div class="itemView" v-for="(item,index) in list" :key="index" v-else>

        <img :src="item.thumbnail2List ? item.thumbnail2List : require('@assets/pic_kuangjituoguan.png')" style="width:400px;height: 304px;"/>
        <div style="flex:1;margin-left: 130px">
          <h2 class="item_title_styl">{{item.title}}</h2>
          <div class="project-attr_wrap">
            <div class="attr-container" v-for="(text, index) in item.otherAttrs" :key="index">
              <span class="labelfont">{{text.attr}}</span>
              <span class="labelval">{{text.description}}</span>
            </div>
          </div>
          <el-button size="lg" v-text="$t('text25')" type="primary" class="btn" @click="$router.push({path:'/index/miningMachine_detail',query: {
                productId: item.productId
              }})"/>
        </div>
      </div>
    </div>



  </el-container>
</template>

<script>
export default {
  name: "kuangji",
  data(){
    return {
      loadingMiner: true,
      list:[],
    }
  },
  mounted() {
    // http://compute.cacheily.com/fe-origin-hashrate-api/getSellMiningProject
    this.getKuangji();
  },
  methods:{
    toLoading() {
      return this.$loading({
        background: 'rgba(0, 0, 0, 0.2)',
        target: 'minging-content',
      })
    },
    async getKuangji() {
      this.toLoading();
      let list = await this.$http({
        url: "/project_sku",
        hostType: "/fe-origin-hashrate-api",
        params: {
          type: 2,
          showTag: 2, //pc
          page: 1,
          pageSize: 100,
          attrKvKey: "origin_product_list_attr",
        },
      });
      this.toLoading().close();
      this.loadingMiner = false;
      const { data } = list;
      console.log(data, 'data');
      this.list = data.data;
    },
  }
}
</script>

<style scoped>

#image {
  position: relative;
}
#image img{
  margin-bottom: 50px;
}
.minging-content {
  width: 100%;
  min-height: 424px;
}
.itemView{
  width:1200px;
  background: #fff;
  padding: 30px 0;
  display: flex;
  margin:0 auto;
  margin-bottom:30px;
  transition: .4s;
  padding-left: 83px;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
}
.itemView:hover{
  transform: scale(1.05);
}
.labelfont{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  text-align: left;
}
.labelval{
  font-size: 16px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}

.btn {
  padding:12px 53px;
}

.rowitemdec{
  display: flex;
  margin-top: 20px;
}

.ada h2,.ada p{
  color: #FFFFFF;
}
.ada h2{
  font-size: 50px;

}
.ada p{
  font-size: 16px;
  line-height: 25px;
}
.ada{
  left: 360px;
  top: 90px;
}
.project-attr_wrap {
  display: flex;
  justify-content:flex-start;
  width: 571px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.attr-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.item_title_styl {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
}
.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
}
.content-container>h2 {
  font-size: 50px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 70px;
}
.content-container>p {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.banner{
  height: 350px;
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}
.banner{
  height: 350px;
  overflow: hidden;
}
.banner img{max-width: 100%;height: 100%}

</style>
