export default {
  bind(el, options, vnode) {
    if (!options.value) return;
    const index = vnode.context.zIndex - 1;
    const dom = document.createElement('div');
    dom.style.zIndex = index;
    dom.className = 'v-mask';
    dom.onclick = () => {
      vnode.context.closed();
    };
    vnode.context.maskDom = dom;
    document.body.appendChild(dom);
    document.body.classList.add('lock-scroll');
  },
  unbind(el, options, vnode) {
    const { maskDom: dom } = vnode.context;
    if (!dom) return;
    dom.onclick = null;
    document.body.classList.remove('lock-scroll');
    document.body.removeChild(dom);
    vnode.context.maskDom = null;
  },
};
