// import cookie from 'cookie';
import http from '../api/axios';
import Cookie from 'js-cookie';
const state = () => ({
  // 是否登录
  isLogin: false,
  // 用户信息
  userInfo: null,
  // token
  token: '',
  lan: 'el_GR',
  // 公共币种列表
  coinList: [],
  // 设备信息
  deviceType: null,
  // 汇率列表
  rate: null,
});
// const SET_LOGIN_STATUS = SET_LOGIN_STATUS;
// const SET_USER_INFO = SET_USER_INFO;
// const SET_TOKEN = SET_TOKEN;
// const SET_COIN_LIST = SET_COIN_LIST;
// const SET_DEVICETYE = SET_DEVICETYE;
// const SET_RATE = SET_RATE;
const mutations = {
  SET_LOGIN_STATUS(state, status) {
    state.isLogin = status;
  },
  SET_USER_INFO(state, userInfo) {
    state.userInfo = userInfo;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_COIN_LIST(state, coinList) {
    state.coinList = coinList;
  },
  SET_DEVICETYE(state, deviceType) {
    state.deviceType = deviceType;
  },
  SET_RATE(state, rate) {
    state.rate = rate;
  },
};

const actions = {
  // 获取用户信息
  async getUserInfo({ commit }) {
    const re = await http({
      url: '/common/user_info',
    });
    if (re.code.toString() === '0') {
      console.log(re, 'er');
      commit('SET_USER_INFO', re.data);
      commit('SET_LOGIN_STATUS', true);
    }else {
      commit('SET_LOGIN_STATUS', false);
      commit('SET_USER_INFO', null);
    }
  },
  async setToken({commit}) {
    commit('SET_TOKEN', Cookie.get('token'));
  }
};

export default {
  state,
  mutations,
  actions,
};
