import http from '../api/axios';

const state = () => ({
    websiteConfig: null
});

const mutations = {
    setWebsiteConfig(state, status) {
        state.websiteConfig = status;
    }
}
const actions = {

    //拉取站点配置信息
    async getWebsite({ commit }) {
        const re = await http({
            url: '/common/kv',
            hostType: "/fe-origin-hashrate-api",
            params: {keys: 'MERCHANT_LOGO_CONTACTUS_CONFIG'},
            method: 'post'
        });
        if (re.code.toString() === '0') {
            // console.log('re.data ', re.data['MERCHANT_LOGO_CONTACTUS_CONFIG'])
            commit('setWebsiteConfig', re.data['MERCHANT_LOGO_CONTACTUS_CONFIG']);
        }
    }
}
export default {
    state,
    mutations,
    actions,
};


