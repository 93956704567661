<template>
  <el-container>
    <el-main>
      <el-card class="box-card">
        <el-table
            :data="tableData"
            border
            style="width: 100%">
          <el-table-column
              type="index"
              :label="$t('text186')">
          </el-table-column>
          <!-- 下单时间 -->
          <el-table-column
              :label="$t('orderTime')">
              <template slot-scope="props">{{moment(props.row.orderTime).format('YYYY-MM-DD')}}</template>
          </el-table-column>
          <el-table-column
              prop="orderNum"
              :label="$t('text187')">
          </el-table-column>
          <el-table-column
              prop="tokenAmount"
              :label="$t('text188')"
          >
          </el-table-column>

          <el-table-column
              prop="miningCycle"
              :label="$t('text22')"
          >
          </el-table-column>
          <el-table-column

              :label="`${$t('text190')}(%)`"
          >
            <template slot-scope="props">{{(props.row.serviceFeeRatio)?props.row.serviceFeeRatio:'--'}}</template>
          </el-table-column>
          <el-table-column
              :label="$t('text191')"
          >
            <template>{{'T+1'}}</template>
          </el-table-column>
          <el-table-column
              prop="payAmount"
              :label="$t('text192')"
          >
          </el-table-column>
          <el-table-column
              prop="paySymbol"
              :label="$t('text193')"
          >
          </el-table-column>
          <el-table-column

              :label="$t('text194')"
          >
            <template slot-scope="props">{{(props.row.profitRatio)?props.row.profitRatio:'--'}}</template>
          </el-table-column>
          <!-- 订单状态 已支付、待挖矿、 挖矿中、已结束 -->
           <el-table-column
              :label="$t('orderStatus')"
          >
            <template slot-scope="props">{{ $t(`orderStatusList.${props.row.orderStatus}`) }}</template>
          </el-table-column>
        </el-table>
      </el-card>

      <el-pagination
          style="margin-top: 20px;float: right"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="total">
      </el-pagination>
    </el-main>

  </el-container>


</template>

<script>
import moment from 'moment';
export default {
  name: "myOrder",
  data() {
    return {
      moment,
      total:0,
      currentPage: 1,
      tableData: [],
      pageSize:10,
    }
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    handleCurrentChange(val) {
      this.getOrderList(val);
    },
    async getOrderList(page=1){
      const result = await this.$http({
        url: '/getOrderList',
        hostType: '/fe-origin-hashrate-api',
        params: {
          "page":page,
          "pageSize":this.pageSize,
          "paramType":"calcuPower"
        }
      })
      const {code,data,msg} = result;
      if(code=="0"){
        this.total=data.count;
        this.tableData=data.orderList;
      }else{
        this.$notify.error(msg);
      }
    }
  }
}
</script>

<style scoped>

</style>
