<template>
  <div :style="{padding: padding, height: height}">
    <h1 style="font-size:32pt; line-height:115%; margin:0pt 0pt 7.05pt;text-align: center"><span
        style="color:#333333; font-family:-apple-system; font-size:32pt; font-style:normal; font-weight:bold; text-decoration:none; text-transform:none">{{$t('submitMessageRestltTitle')}}</span>
    </h1>
    <p style="font-size:15pt; line-height:115%; margin:0pt"><span
        style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; text-transform:none">&nbsp;</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt">
      <span style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none">{{$t('submitMessageRestltBody')}}</span>
    </p>
    <p style="font-size:15pt; line-height:115%; margin:0pt">
      <span style="color:#333333; font-family:-apple-system; font-size:15pt; font-style:normal; font-weight:normal; text-decoration:none; text-transform:none"> <el-link type="primary" href="/index/home">{{min}} {{$t('toHome')}}</el-link></span>
    </p>


  </div>
</template>

<script>
export default {

  data() {
    return {
      height:undefined,
      padding:'20px',
      min:5,
      timer:undefined
    };
  },
  mounted: function() {
    this.height = window.innerHeight - 200 + "px"
    this.timer = window.setInterval(this.back,1000)
  },
  methods: {
    back(){
      if(this.min){
        this.min = (this.min-1)? this.min-1 : 0
      }
      if(this.min === 0){

        if(this.timer){
          clearInterval(this.timer)
        }
        this.$router.push({path:"/index/home"})
      }
    }
  }
};
</script>

<style scoped>

</style>
