import Vue from 'vue';
import ToastMain from './index.vue';

const ToastConstructor = Vue.extend(ToastMain);
let toastPool = null;

const getAnInstance = () => {
  if (!toastPool) {
    toastPool = new ToastConstructor({
      el: document.createElement('div'),
    });
  }
  return toastPool;
};

const removeDom = (event) => {
  if (event.target.parentNode) {
    event.target.parentNode.removeChild(event.target);
  }
};

ToastConstructor.prototype.close = function close() {
  this.visible = false;
  this.$el.addEventListener('transitionend', removeDom);
  this.closed = true;
};

const Toast = (options = {}) => {
  const duration = options.duration || 2000;

  const instance = getAnInstance();
  instance.closed = false;
  clearTimeout(instance.timer);
  instance.message = typeof options === 'string' ? options : options.message;
  instance.type = options.type || '';

  document.body.appendChild(instance.$el);
  instance.visible = true;
  instance.$el.removeEventListener('transitionend', removeDom);
  duration
    && (instance.timer = setTimeout(() => {
      if (instance.closed) return;
      instance.close();
    }, duration));
  return instance;
};
['success', 'error', 'info', 'warning'].forEach((type) => {
  Toast[type] = (options) => {
    const ops = typeof options === 'string' ? { message: options } : options;
    return Toast({
      type,
      ...ops,
    });
  };
});

export default Toast;
