<template>
  <el-container style="background: #fff" direction="vertical">
    <div class="itemView">
      <img :src="details.thumbnail2Detail? details.thumbnail2Detail[0] : require('@assets/pic_kuangjituoguan.png')" style="width:500px;"/>
      <div style="flex:1;margin-left: 120px">
        <h2>{{details.title}}</h2>
        <!-- <p class="tipBlock">{{$t('text184')+': '+details.zengsong}}</p> -->
        <div class="clear"></div>
          <div class="project-attr_wrap">
           <div class="attr-container" v-for="(item, index) in productMinerAttr" :key="index">
              <span class="labelfont">{{item.attr}}</span>
              <span class="labelval">{{item.description}}</span>
           </div>
          </div>
        <el-button size="lg" v-text="$t('text45')" type="primary" class="btn" @click="openDialog"/>
      </div>
    </div>


    <!--    底部详情-->
    <div class="container">
      <h3 class="lab-title-styl">{{$t('text107')}}</h3>

      <div class="line" />
      <div class="setting-attr-wrap">
        <div class="productMinerOtherAttr-attr-container" v-for="(item, index) in productMinerOtherAttr" :key="index">
          <span class="productMinerOtherAttr-attr">{{ item.attr }}</span>
          <span>{{ item.description }}</span>
        </div>
      </div>

      <h3 class="lab-title-styl">{{$t('text108')}}</h3>
      <div class="line" />

      <p class="productMinerIntroduce-description">
         <VueMarkdown :source="productMinerIntroduce[0]&&productMinerIntroduce[0].description" :toc-anchor-link="false" :toc="true"></VueMarkdown>
      </p>
    </div>
  </el-container>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import { getCookie } from '../../utils/cookieUtil';
export default {
  name: "kuangjidetail",
    components: {
    VueMarkdown
  },
  data(){
    return {
      loadingMiner: true,
      details: {},
      productMinerAttr: [],
      productMinerOtherAttr: [],
      productMinerIntroduce: [],
      lan: getCookie('lan'),
    }
  },
  mounted() {
    console.log(this.s_googleStatus, 's_googleStatus');
    this.getData(this.$route.query.productId);
  },
  methods:{
    toLoading() {
      return this.$loading({
        background: 'rgba(0, 0, 0, 0.2)',
      })
    },
    pushPage(url){
      location.href=url;
    },
    getData(id) {
      this.toLoading();
      this.$http({
        url: '/project_sku_detail',
        hostType: '/fe-origin-hashrate-api',
        params: {
          productId: id
        },
      }).then(({data,code}) => {
        console.log('矿机详情返回值:', data);
        this.toLoading().close();
        this.loadingMiner = false
        if(code == '0') {
          this.details = data;
          this.productMinerAttr = data.attrs[this.lan].productMinerAttr;
          this.productMinerOtherAttr = data.attrs[this.lan].productMinerOtherAttr;
          this.productMinerIntroduce = data.attrs[this.lan].productMinerIntroduce;
          console.log(this.details.thumbnail2Detail);
        }
      })
    },
    openDialog(){
      this.$emit("update");
    }
  }
}
</script>

<style scoped>
.itemView{
  width:1200px;
  background: #fff;
  padding-bottom: 30px;
  display: flex;
  margin:0 auto;
  margin-top: 50px;
  margin-bottom:50px;
}
.labelfont{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  text-align: left;
}
.labelval{
  font-size: 16px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
}

.btn{
  /* width:170px;
  height:44px; */
  padding: 17px 98px;
}

.rowitemdec{
  display: flex;
  margin-top: 20px;
}

.container{
  width: 1200px;
  margin: 0 auto;
}

.commonfont{
  color:#999999;
  font-size: 14px;
  margin-top: 13px;
  margin-bottom: 80px;
}

.fonttitle{
  font-size: 16px;
  color:#000000;
  margin-top: 30px;
  margin-bottom: 10px;
}
.line{
  width: 100%;
  height: 1px;
  background-color: #D8D8D8;
  margin-top: 12px;
  margin-bottom: 30px;
}
.commonval{
  font-size: 12px;
  color: #333;
  float: right;
}
.tipBlock{
  padding:4px 10px;
  background: rgba(243, 152, 0, 0.1);
  border:1px solid #F39800;
  font-size: 14px;
  border-radius: 5px;
  float: left;
  margin-top: 30px;
  color: #000000;

}
.clear{
  overflow: hidden;
  clear: both;
  width: 0;
  height: 0;
  display: block;
}
.project-attr_wrap {
  display: flex;
  justify-content:flex-start;
  width: 571px;
  flex-wrap: wrap;
  margin: 30px 0 10px 0;
}
.attr-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.setting-attr-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
.productMinerOtherAttr-attr-container {
  width: 592px;
  height: 44px;
  background: #F3F4F9;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.productMinerOtherAttr-attr-container:last-child {
  margin-bottom: 0;
}
.productMinerOtherAttr-attr {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  line-height: 16px;

}
.productMinerOtherAttr-description {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 16px;
}
.productMinerIntroduce-description {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 24px;
  margin-bottom: 63px;
}
.lab-title-styl {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 25px;
}
</style>
