<template>
<el-container direction="vertical" style="width:100%;overflow: hidden">
  <div class="banner">
    <img src="@assets/banner_suanlishichang.png" alt="" style="width: 100%;">
    <div class="content-container">
      <h2>{{$t('text110')}}</h2>
      <p>{{$t('text111')}}</p>
      <p>{{$t('text112')}}</p>
    </div>
  </div>
    <div class="compute-container">
      <template v-if="!loadingPower && list.length<=0">
        <el-empty style="height: 500px"></el-empty>
      </template>

      <div class="itemView" v-for="item in list" :key="item.id" v-else>

        <img :src="item.thumbnail2List ? item.thumbnail2List : require('@assets/pic_suanlishichang.png')" style="width:400px;height: 304px;margin-left: 90px"/>
        <div style="margin-left: 130px;width:calc(100% - 620px);">
          <div class="projectDisplayName-styl">
            <span>
              {{ item.title }}
            </span>
            <span class="icons-status-styl pre-sale" v-if="item.productStatus === 0">{{$t(`text200.${item.productStatus}`)}}</span>
            <span class="icons-status-styl sale" v-else-if="item.productStatus === 1">{{$t(`text200.${item.productStatus}`)}}</span>
            <span class="icons-status-styl sale-out" v-else>{{$t(`text200.${item.productStatus}`)}}</span>
          </div>

          <div class="rowitemdec">
            <div style="flex:1">
              <p class="labelfont">{{$t('text47')}}</p>
              <p class="labelval">{{item.miningCycle}}{{$t('text181')}}</p>
            </div>
            <div style="flex:1">
              <p class="labelfont">{{$t('text48')}}</p>
              <p class="labelval">{{item.serviceRate}}%</p>
            </div>
          </div>

          <div class="rowitemdec">
            <div style="flex:1">
              <p class="labelfont">{{$t('text49')}}</p>
              <p class="labelval">T + 1</p>
            </div>
            <div style="flex:1">
              <p class="labelfont">{{$t('text50')}}</p>
              <p class="labelval">{{ $t("text169") + " " + item.minBuy }}T ~ {{ item.maxBuy + 'T' }}</p>
            </div>
          </div>

          <div class="rowitemdec">
            <div style="flex:1">
              <p class="labelfont">{{$t('text51')}}</p>
              <p class="labelval">{{item.profitRatio}}%</p>
            </div>
            <div style="flex:1">
              <p class="labelfont">{{$t('text52')}}</p>
              <p class="labelval" style="color: #3E26D1">{{item.price  }} {{item.paySymbol | filterSymbol}}/T</p>
            </div>
          </div>

          <el-button size="lg" v-text="$t('text25')" type="primary" :class="['btn',{disableByn:item.productStatus==2}]" @click="$router.push({path:'/index/cloudComputingPower_detail/'+item.productId})"
                    :disabled="item.productStatus==2"

          />
        </div>
      </div>
    </div>



</el-container>
</template>

<script>
export default {
  name: "yunsuanli",
  filters:{
    filterSymbol(item) {
      if(!item){
        return item
      }
      return item.toLowerCase() == 'usdt' ? '$' : item
    }
  },
  data(){
    return {
      list:[],
      loadingPower: true
    }
  },
  mounted() {
    this.getData();
  },
  methods:{
    toLoading() {
      return this.$loading({
        background: 'rgba(0, 0, 0, 0.2)',
        target: 'compute-container',
      })
    },
    // 获取算力数据
    async getData() {
      this.toLoading();
      const result = await this.$http({
        url: "/project_sku",
        hostType: "/fe-origin-hashrate-api",
        params: {
          page: 1,
          pageSize: 100,
          showTag: 2,
          type: 1,
        },
      });
      this.toLoading().close();
      this.loadingPower = false
      const { code, data } = result;
      if (code == "0") {
        if (data.data.length) {
          this.list =data.data;
        }
      }
    },

  }
}
</script>

<style scoped>

#image img{
  margin-bottom: 50px;
}
.compute-container {
  width: 100%;
  min-height: 424px;
}
.itemView{
  width:1200px;
  /*height: 363px;*/
  background: #fff;
  padding: 30px 0;
  display: flex;
  margin:0 auto;
  margin-bottom:30px;
  transition: .4s;
  align-items: flex-start;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
}
.itemView:hover{
  transform: scale(1.05);
}
.labelfont{
  font-size: 12px;
  color:#999999;
  text-align: left;
}
.labelval{
  font-size: 14px;
  color:#333333 ;
}

.btn{
  width:170px;height:44px;margin-top: 20px;
}

.rowitemdec{
  display: flex;
  margin-top: 20px;
}
.ada h2,.ada p{
  color: #FFFFFF;
}
.ada h2{
  font-size: 50px;

}
.ada p{
  font-size: 16px;
  line-height: 25px;
}
.ada{
  left: 360px;
  top: 90px;
}

.tag{
  width: 64px;
  height: 24px;
  color: #fff;
  position: absolute;
  right: 10px;
  top:0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.status0{
  /*预售中*/
  background: url('~@/assets/yushouzhong.png') no-repeat center;
}
.status1{
  /*出售中*/
  background: url('~@/assets/chushouzhong.png') no-repeat center;
}
.status2{
  /*已售罄*/
  background:#F6F6F6;/*Old gradient for webkit*/
  color:#999
}
.disableByn{
  background: #F6F6F6!important;
  color: #999!important;
  border: 0!important;
}
.content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
}
.content-container>h2 {
  font-size: 50px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 70px;
}
.content-container>p {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.banner{
  height: 350px;
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}
.banner{
  height: 350px;
  overflow: hidden;
}
.banner img{max-width: 100%;height: 100%}
.icons-status-styl {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 16px;
  padding: 4px 14px;
  border-radius: 3px;
  margin-left: 16px;
}
.pre-sale {
  background: linear-gradient(75deg,#4129F8 0,#AD7CFF 100%);
}
.sale {
  background: linear-gradient(270deg,#FE6F00 0,#9D54D1 100%);
}
.sale-out {
  background: #F6F6F6;
  color: #999;
}
.projectDisplayName-styl {
  display: flex;
  align-items: center;
}
</style>
