<template>
  <el-container class="origin-index" style="background: #F7F9FC">

    <el-header class="mnav">
      <el-row style="width: 100%;display: flex;align-items: center">
        <div style="min-width: 250px">
          <img :src="getWebsiteConfigCache('pcLogoOS')" class="logo" @click="$router.push({path:'/'})"/>
        </div>
        <el-col style="min-width:600px;width:auto;">
          <router-link to="/index/home" class="navlink" active-class="navlinkAct">{{ $t('text195') }}</router-link>
          <!-- <router-link to="/index/cloudComputingPower" class="navlink" active-class="navlinkAct">{{ $t('suanlishichang') }}</router-link>
          <router-link to="/index/miningMachine" class="navlink" active-class="navlinkAct">{{ $t('tuoguan') }}</router-link> -->
          <router-link to="/index/aboutUs" class="navlink" active-class="navlinkAct">{{ $t('aboutus') }}</router-link>
        </el-col>

        <div style="flex:1">
          <div style="display: flex;align-items: center;justify-items: flex-end;float: right">
<!--

            <template v-if="islogin">
              <el-dropdown @command="pusholdPage">

                <img src="@assets/ic_my_unchecked.png" width="24px" style="margin-right: 30px"/>
                <el-dropdown-menu slot="dropdown" style="background-color: #01020C;border:0">
                  <el-dropdown-item class="userAccount-item" command="a">
                    <div class="userAccount" style="text-align: left">
                      <p>{{ userinfo.userAccount }}</p>
                      <span style="color: #999;font-size: 13px">{{ $t('text146') }}:{{ getLabelUserAccount }}</span>
                    </div>
                    <img src="@assets/arrow.png" alt="" style="width: 14px;height: 16px">
                  </el-dropdown-item>
                  <el-dropdown-item style="color:#fff" command="0">{{ $t('text141') }}</el-dropdown-item>
                  <el-dropdown-item style="color:#fff" command="5">{{ $t('text185') }}</el-dropdown-item>

                  <el-dropdown-item style="color:#fff" command="1">{{ $t('text142') }}</el-dropdown-item>

                  <el-dropdown-item style="color:#fff" command="2">{{ $t('text143') }}</el-dropdown-item>
                  <el-dropdown-item style="color:#fff" command="3">{{ $t('text144') }}</el-dropdown-item>

                  <el-dropdown-item style="color:#fff" command="4">{{ $t('text145') }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template v-else>
              <el-button type="text" style="color:#fff" @click="pushPage(null)">
                {{ $t('register') }}
              </el-button>
              <el-button type="primary" class="loginbtn" @click="pushPage(null)">
                {{ $t('login') }}
              </el-button>
            </template>
-->



<!--            @click="pushPage(baseUrlHost2+'/en_US/app-down/')"-->
            <div style="padding-right: 10px;width:142px;display: flex;align-itexms: center;justify-content:center;cursor: pointer;"
                 slot="reference"
                 @click="pushPage(null)"
            >
              <img src="@assets/ic_app_download.png" alt="" style="width: 9px;height: 14px;margin-right: 10px;"/>
              <span
                  style="color: #fff;font-size: 12px;border-right-width: 1px;border-right-color: #7C7C7C;padding-right: 35px;border-right-style: solid;">{{$t('text197')}}</span>

            </div>




            <el-dropdown @command="handleCommand" :visible-arrow="false">
              <div style="height:50px;min-width:150px;display: flex;align-items: center;cursor: default">
                <img src="@assets/ic_client_gray.png"
                     style="width:16px;height:16px;margin-right: 10px;margin-left: 10px"/>
                <span style="color:#fff;font-size: 14px">{{getLabelByLan}}</span>
                <img src="@assets/ic_drop_down.png" style="width:8px;height:4px;margin-left: 6px"/>
              </div>
              <el-dropdown-menu slot="dropdown" style="background-color: #01020C;border:0;padding-top: 0">
                <el-dropdown-item style="color:#fff" command="0">中文（繁体）</el-dropdown-item>
                <el-dropdown-item style="color:#fff" command="1">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </el-row>
    </el-header>
    <div style="height:98px "/>



    <div style="padding:0;text-align: center;position:fixed;width:160px;height:160px;background:#000;top: 98px;right: 165px;z-index: 999999;" v-show="iscCdeVis">
      <div style="width: 100px;height: 100px;margin:5px auto;padding:5px;background: #fff" ref="qrCodeUrl"/>
      <p style="color: #fff;font-size: 14px;margin-top: 10px">{{$t('text198')}}</p>
    </div>

    <router-view @update="showDialog()"/>

    <el-footer class="footer">
      <div class="container footer-container">

        <div style="margin-right: 215px">
            <img :src="getWebsiteConfigCache('pcFootLogoOS')" style="width:135px;height: 18px"/>

            <div style="margin-top: 32px" id="iconbottom">
              <img src="@assets/ic_press_tw.png" style="width:26px;height: 26px;float: left;margin-right: 20px" v-if="getWebsiteConfigCache('twitter') !== 'twitter'" @click="pushPage(getWebsiteConfigCache('twitter'),true)"/>
              <img src="@assets/ic_press_t.png" style="width:26px;height: 26px;float: left;margin-right: 20px" v-if="getWebsiteConfigCache('telegram') !== 'telegram'" @click="pushPage(getWebsiteConfigCache('telegram'),true)"/>
<!--              <img src="@assets/ic_press_di.png" style="width:26px;height: 26px;float: left;margin-right: 20px" @click="pushPage('https://discord.gg/8gz8gxBQ')"/>-->
              <img src="@assets/ic_press_mail.png" style="width:26px;height: 26px;float: left;margin-right: 20px" @click="pushPage('mailto:' + getWebsiteConfigCache('salesEMail'),true)"/>
            </div>
        </div>
        <div style="flex:1;align-items:flex-start;display: flex;">
          <div class="footerLink">
            <p>{{ $t('text30') }}</p>
            <ul>
              <li @click="$router.push({path:'/index/cloudComputingPower'})">{{ $t('hashRate_market') }}</li>
              <li @click="$router.push({path:'/index/miningMachine'})">{{ $t('text31') }}</li>
            </ul>
          </div>

          <div class="footerLink">
            <p>{{ $t('text32') }}</p>
            <ul>
              <li @click="$router.push({path:'/index/aboutUs'})">{{ getWebsiteConfigCache('title') }}</li>
              <li @click="pushPage('mailto:'+getWebsiteConfigCache('salesEMail'))">{{ $t('text36') }}</li>
            </ul>
          </div>
          <div class="footerLink">
            <p>{{ $t('text33') }}</p>
            <ul>
              <li @click="pushPrivacyAgreement">{{ $t('text37') }}</li>
            </ul>
          </div>
          <div class="footerLink">
            <p>{{ $t('text34') }}</p>
            <ul>
              <li @click="pushPage('https://filecoin.io/',true)">{{ $t('text38') }}</li>
              <li @click="pushPage('https://filscan.io/calculator',true)">{{ $t('text39') }}</li>
              <li @click="pushPage('https://protocol.ai',true)">{{ $t('text40') }}</li>
              <li @click="pushPage('https://fil.org',true)">{{ $t('text41') }}</li>
            </ul>
          </div>
        </div>


      </div>

      <el-row>
        <el-col :span="12">
          <p style="text-align: right;margin-right: 29px">{{ $t('text155') }}:{{getWebsiteConfigCache('supportEMail')}}</p>
        </el-col>
        <el-col :span="12">
          <p style="text-align: left;margin-left: 29px">{{ $t('text156') }}: {{getWebsiteConfigCache('salesEMail')}}</p>
        </el-col>
      </el-row>

      <p style="color: #666666;text-align: center;height: 20px;position:absolute;width: 300px;left:50%;margin-left: -150px">
        Copyright © 2021 {{getWebsiteConfigCache('title')}} Reserved️</p>
    </el-footer>


    <el-dialog
        :title="$t('text151')"
        :visible="visible"
        width="30%"
        :before-close="closedialog"
    >
      <span>{{ $t('text154') }}</span>
      <span slot="footer" class="dialog-footer">
                        <el-button @click="visible = false">{{ $t('text152') }}</el-button>
                        <el-button type="primary"
                                   @click="pushPage(baseUrlHost2+'/'+getCookieVal('lan')+'/app-down/')">{{ $t('text153') }}</el-button>
                      </span>
    </el-dialog>


    <div class="rightutil">
      <div class="itembtn" @click="pushPage('https://t.me/FIL_Cloud',true)">
        <img src="@assets/ic_zaixian.png" style="width: 26px"/>
        <span>{{$t('text172')}}</span>
      </div>

      <div class="itembtn" @click="dialogClueCollect=true">
        <img src="@assets/ic_gongdan.png" style="width: 26px"/>
        <span>{{$t('text174')}}</span>
      </div>

      <div class="itembtn" @click="totop">
        <img src="@assets/ic_zhiding.png" style="width: 26px"/>
        <span>{{$t('text171')}}</span>
      </div>
    </div>




    <el-dialog :visible="dialogClueCollect" width="500px" :before-close="dialogClueCollectClose">
      <div slot="title">
        <h2 style="text-align: center;color: #333;font-size: 18px">{{$t('text175')}}</h2>
      </div>
      <el-form label-position="top">
        <el-form-item :label="$t('text176')" :required="true">
          <el-input autocomplete="off" v-model="name" ref="iname"></el-input>
        </el-form-item>


        <el-form-item :label="$t('text177')" :required="true">
          <el-select v-model="areaCode" placeholder="请选择" style="float: left;width: 140px">
            <el-option
                v-for="(item,index) in countryList"
                :key="index"
                :label="item.name"
                :value="item.areaCode">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">+{{ item.areaCode }}</span>
            </el-option>
          </el-select>
          <el-input autocomplete="off" v-model="tel" ref="itel" style="float:left;width: calc(100% - 150px);margin-left: 10px" :placeholder="areaCode"></el-input>
        </el-form-item>
        <el-form-item :label="$t('text178')" :required="true">
          <el-input autocomplete="off" v-model="email" ref="iemail"></el-input>
        </el-form-item>
        <el-form-item :label="$t('text204')" :required="true">
          <el-select v-model="mediaName" placeholder="请选择" style="float: left;width: 140px">
            <el-option
                v-for="item in dataMedia"
                :key="item.type"
                :label="item.type"
                :value="item.type">
              <span style="float: left">{{ item.type }}</span>
            </el-option>
          </el-select>
          <el-input autocomplete="off" v-model="mediaCode" ref="mediaCode" style="float:left;width: calc(100% - 150px);margin-left: 10px" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('text179')" :required="true">
          <el-input autocomplete="off"
                    type="textarea"
                    v-model="dec"
                    :rows="2"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submit" v-preventReClick style="width: 120px;height: 44px">{{$t('text180')}}</el-button>
      </div>
    </el-dialog>




  </el-container>
</template>

<script>
import {delCookie, getCookie, setCookie} from "../../utils/cookieUtil";
import QRCode from 'qrcodejs2'
// import notice from "../notice";
// import * as cookies from 'js-cookie'

export default {
  name: "index",
  data() {
    return {
      islogin: false,
      userinfo: {},
      visible: false,
      // gonggaoList: [],
      currentIndex: 0,
      curLan: '',
      name:"",
      tel:"",
      email:"",
      dec:"",
      mediaCode:"",
      mediaName:"WeChat",

      dialogClueCollect:false,
      iscCdeVis:false,
      areaCode:"86",
      countryList: [
        {name:"中國 (China)",areaCode:"86"},
        {name:"中國香港 (Hong Kong)",areaCode:"852"},
        {name:"中國澳門 (Macau)",areaCode:"853"},
        {name:"中國台灣 (Taiwan)",areaCode:"886"},
        {name:"日本 (Japan)",areaCode:"81"},
        {name:"韓國 (South Korea)",areaCode:"82"},
        {name:"美國 (USA or Canada)",areaCode:"1"},
        {name:"阿富汗 (Afghanistan)",areaCode:"93"},
        {name:"阿爾巴尼亞 (Albania)",areaCode:"355"},
        {name:"阿爾及利亞 (Algeria)",areaCode:"213"},
        {name:"薩摩亞 (American Samoa)",areaCode:"684"},
        {name:"安道爾共和國 (Andorra)",areaCode:"376"},
        {name:"安哥拉 (Angola)",areaCode:"244"},
        {name:"安圭拉島 (Anguilla)",areaCode:"1264"},
        {name:"南極洲 (Antarctica)",areaCode:"672"},
        {name:"安提瓜和巴布達 (Antigua and Barbuda)",areaCode:"1268"},
        {name:"阿根廷 (Argentina)",areaCode:"54"},
        {name:"亞美尼亞 (Armenia)",areaCode:"374"},
        {name:"阿魯巴 (Aruba)",areaCode:"297"},
        {name:"澳大利亞 (Australia)",areaCode:"61"},
        {name:"奧地利 (Austria)",areaCode:"43"},
        {name:"阿塞拜疆 (Azerbaijan)",areaCode:"994"},
        {name:"巴哈馬 (Bahamas)",areaCode:"1242"},
        {name:"巴林 (Bahrain)",areaCode:"973"},
        {name:"孟加拉國 (Bangladesh)",areaCode:"880"},
        {name:"巴巴多斯 (Barbados)",areaCode:"1246"},
        {name:"白俄羅斯 (Belarus)",areaCode:"375"},
        {name:"比利時 (Belgium)",areaCode:"32"},
        {name:"伯利茲城 (Belize)",areaCode:"501"},
        {name:"貝寧 (Benin)",areaCode:"229"},
        {name:"百慕大 (Bermuda)",areaCode:"1441"},
        {name:"不丹 (Bhutan)",areaCode:"975"},
        {name:"玻利維亞 (Bolivia)",areaCode:"591"},
        {name:"波斯尼亞和黑塞哥維那 (Bosnia and Herzegovina)",areaCode:"387"},
        {name:"博茨瓦納 (Botswana)",areaCode:"267"},
        {name:"巴西 (Brazil)",areaCode:"55"},
        {name:"英屬印度洋領地 (British Indian Ocean Territory)",areaCode:"246"},
        {name:"文萊達魯薩蘭國 (Brunei Darussalam)",areaCode:"673"},
        {name:"保加利亞 (Bulgaria)",areaCode:"359"},
        {name:"布基納法索 (Burkina Faso)",areaCode:"226"},
        {name:"布隆迪 (Burundi)",areaCode:"257"},
        {name:"柬埔寨 (Cambodia)",areaCode:"855"},
        {name:"喀麥隆 (Cameroon)",areaCode:"237"},
        {name:"佛得角 (Cape Verde)",areaCode:"238"},
        {name:"開曼群島 (Cayman Islands)",areaCode:"1345"},
        {name:"中非共和國 (Central African Republic)",areaCode:"236"},
        {name:"乍得 (Chad)",areaCode:"235"},
        {name:"智利 (Chile)",areaCode:"56"},
        {name:"聖延島 (Christmas Island)",areaCode:"61"},
        {name:"科科斯群島 (Cocos (Keeling) Islands)",areaCode:"61"},
        {name:"哥倫比亞(Colombia)",areaCode:"57"},
        {name:"科摩羅 (Comoros)",areaCode:"269"},
        {name:"剛果 (Congo)",areaCode:"242"},
        {name:"剛果民主共和國(Congo,The Democratic Republic Of The)",areaCode:"243"},
        {name:"庫克群島 (Cook Islands)",areaCode:"682"},
        {name:"哥斯達黎加 (Costa Rica)",areaCode:"506"},
        {name:"科特迪瓦 (Cote D Ivoire)",areaCode:"225"},
        {name:"克羅地亞 (Croatia)",areaCode:"385"},
        {name:"古巴 (Cuba)",areaCode:"53"},
        {name:"塞浦路斯 (Cyprus)",areaCode:"357"},
        {name:"捷克 (Czech Republic)",areaCode:"420"},
        {name:"丹麥 (Denmark)",areaCode:"45"},
        {name:"吉布提 (Djibouti)",areaCode:"253"},
        {name:"多米尼克國 (Dominica)",areaCode:"1767"},
        {name:"多米尼加共和國 (Dominican Republic)",areaCode:"1849"},
        {name:"東帝汶 (East Timor)",areaCode:"670"},
        {name:"厄瓜多爾 (Ecuador)",areaCode:"593"},
        {name:"埃及 (Egypt)",areaCode:"20"},
        {name:"薩爾瓦多 (El Salvador)",areaCode:"503"},
        {name:"赤道幾內亞 (Equatorial Guinea)",areaCode:"240"},
        {name:"愛沙尼亞 (Estonia)",areaCode:"372"},
        {name:"埃塞俄比亞 (Ethiopia)",areaCode:"251"},
        {name:"福克蘭群島(Falkland Islands (Malvinas))",areaCode:"500"},
        {name:"法羅群島 (Faroe Islands)",areaCode:"298"},
        {name:"斐濟 (Fiji)",areaCode:"679"},
        {name:"芬蘭 (Finland)",areaCode:"358"},
        {name:"法國 (France)",areaCode:"33"},
        {name:"法國大都會 (France Metropolitan)",areaCode:"33"},
        {name:"法屬圭亞那 (French Guiana)",areaCode:"594"},
        {name:"法屬玻里尼西亞 (French Polynesia)",areaCode:"689"},
        {name:"加蓬 (Gabon)",areaCode:"241"},
        {name:"岡比亞 (Gambia)",areaCode:"220"},
        {name:"格魯吉亞 (Georgia)",areaCode:"995"},
        {name:"德國 (Germany)",areaCode:"49"},
        {name:"加納 (Ghana)",areaCode:"233"},
        {name:"直布羅陀 (Gibraltar)",areaCode:"350"},
        {name:"希臘 (Greece)",areaCode:"30"},
        {name:"格陵蘭 (Greenland)",areaCode:"45"},
        {name:"格林納達 (Grenada)",areaCode:"1473"},
        {name:"瓜德羅普島 (Guadeloupe)",areaCode:"590"},
        {name:"關島 (Guam)",areaCode:"1671"},
        {name:"危地馬拉 (Guatemala)",areaCode:"502"},
        {name:"幾內亞 (Guinea)",areaCode:"224"},
        {name:"幾內亞比紹 (Guinea-Bissau)",areaCode:"245"},
        {name:"圭亞那 (Guyana)",areaCode:"592"},
        {name:"海地 (Haiti)",areaCode:"509"},
        {name:"洪都拉斯 (Honduras)",areaCode:"504"},
        {name:"匈牙利 (Hungary)",areaCode:"36"},
        {name:"冰島 (Iceland)",areaCode:"354"},
        {name:"印度 (India)",areaCode:"91"},
        {name:"印度尼西亞 (Indonesia)",areaCode:"62"},
        {name:"伊朗 (Iran (Islamic Republic of))",areaCode:"98"},
        {name:"伊拉克 (Iraq)",areaCode:"964"},
        {name:"愛爾蘭 (Ireland)",areaCode:"353"},
        {name:"以色列 (Israel)",areaCode:"972"},
        {name:"意大利 (Italy)",areaCode:"39"},
        {name:"牙買加 (Jamaica)",areaCode:"1876"},
        {name:"約旦 (Jordan)",areaCode:"962"},
        {name:"哈薩克 (Kazakhstan)",areaCode:"7"},
        {name:"肯尼亞 (Kenya)",areaCode:"254"},
        {name:"科威特 (Kuwait)",areaCode:"965"},
        {name:"吉爾吉斯 (Kyrgyzstan)",areaCode:"996"},
        {name:"拉脫維亞 (Latvia)",areaCode:"371"},
        {name:"黎巴嫩 (Lebanon)",areaCode:"961"},
        {name:"萊索托 (Lesotho)",areaCode:"266"},
        {name:"利比里亞 (Liberia)",areaCode:"231"},
        {name:"利比亞 (Libyan Arab Jamahiriya)",areaCode:"218"},
        {name:"列支敦士登 (Liechtenstein)",areaCode:"423"},
        {name:"立陶宛 (Lithuania)",areaCode:"370"},
        {name:"盧森堡 (Luxembourg)",areaCode:"352"},
        {name:"馬達加斯加 (Madagascar)",areaCode:"261"},
        {name:"馬拉維 (Malawi)",areaCode:"265"},
        {name:"馬來西亞 (Malaysia)",areaCode:"60"},
        {name:"馬爾代夫 (Maldives)",areaCode:"960"},
        {name:"馬里 (Mali)",areaCode:"223"},
        {name:"馬爾他 (Malta)",areaCode:"356"},
        {name:"馬提尼克島 (Martinique)",areaCode:"596"},
        {name:"毛里塔尼亞 (Mauritania)",areaCode:"222"},
        {name:"毛里求斯(Mauritius)",areaCode:"230"},
        {name:"馬約特 (Mayotte)",areaCode:"262"},
        {name:"墨西哥 (Mexico)",areaCode:"52"},
        {name:"密克羅尼西亞 (Micronesia)",areaCode:"691"},
        {name:"摩爾多瓦 (Moldova)",areaCode:"373"},
        {name:"摩納哥 (Monaco)",areaCode:"377"},
        {name:"外蒙古 (Mongolia)",areaCode:"976"},
        {name:"黑山共和國 (Montenegro)",areaCode:"382"},
        {name:"蒙特塞拉特 (Montserrat)",areaCode:"1664"},
        {name:"摩洛哥 (Morocco)",areaCode:"212"},
        {name:"莫桑比克 (Mozambique)",areaCode:"258"},
        {name:"緬甸 (Myanmar)",areaCode:"95"},
        {name:"那米比亞 (Namibia)",areaCode:"264"},
        {name:"瑙魯 (Nauru)",areaCode:"674"},
        {name:"尼泊爾 (Nepal)",areaCode:"977"},
        {name:"荷蘭 (Netherlands)",areaCode:"31"},
        {name:"荷蘭安的列斯群島 (Netherlands Antilles)",areaCode:"599"},
        {name:"新喀裡多尼亞 (New Caledonia)",areaCode:"687"},
        {name:"新西蘭 (New Zealand)",areaCode:"64"},
        {name:"尼加拉瓜 (Nicaragua)",areaCode:"505"},
        {name:"尼日爾 (Niger)",areaCode:"227"},
        {name:"尼日利亞 (Nigeria)",areaCode:"234"},
        {name:"諾福克島 (Norfolk Island)",areaCode:"6723"},
        {name:"朝鮮 (North Korea)",areaCode:"850"},
        {name:"北馬里亞納群島 (Northern Mariana Islands)",areaCode:"1670"},
        {name:"挪威 (Norway)",areaCode:"47"},
        {name:"阿曼 (Oman)",areaCode:"968"},
        {name:"巴基斯坦 (Pakistan)",areaCode:"92"},
        {name:"帛琉 (Palau)",areaCode:"680"},
        {name:"巴勒斯坦 (Palestine)",areaCode:"970"},
        {name:"巴拿馬 (Panama)",areaCode:"507"},
        {name:"巴布亞新幾內亞 (Papua New Guinea)",areaCode:"675"},
        {name:"巴拉圭 (Paraguay)",areaCode:"595"},
        {name:"秘魯 (Peru)",areaCode:"51"},
        {name:"菲律賓共和國 (Philippines)",areaCode:"63"},
        {name:"皮特凱恩島 (Pitcairn)",areaCode:"64"},
        {name:"波蘭 (Poland)",areaCode:"48"},
        {name:"葡萄牙 (Portugal)",areaCode:"351"},
        {name:"波多黎各 (Puerto Rico)",areaCode:"1787"},
        {name:"卡塔爾 (Qatar)",areaCode:"974"},
        {name:"留尼汪島 (Reunion)",areaCode:"262"},
        {name:"羅馬尼亞 (Romania)",areaCode:"40"},
        {name:"俄羅斯聯邦 (Russian Federation)",areaCode:"7"},
        {name:"盧旺達 (Rwanda)",areaCode:"250"},
        {name:"美屬薩摩亞 (Samoa)",areaCode:"685"},
        {name:"聖馬力諾共和國 (San Marino)",areaCode:"378"},
        {name:"沙特阿拉伯 (Saudi Arabia)",areaCode:"966"},
        {name:"塞內加爾 (Senegal)",areaCode:"221"},
        {name:"塞爾維亞共和國 (Serbia)",areaCode:"381"},
        {name:"塞舌爾 (Seychelles)",areaCode:"248"},
        {name:"塞拉利昂 (Sierra Leone)",areaCode:"232"},
        {name:"新加坡 (Singapore)",areaCode:"65"},
        {name:"斯洛伐克 (Slovakia (Slovak Republic))",areaCode:"421"},
        {name:"斯洛文尼亞 (Slovenia)",areaCode:"386"},
        {name:"索羅門群島 (Solomon Islands)",areaCode:"677"},
        {name:"索馬里 (Somalia)",areaCode:"252"},
        {name:"南非 (South Africa)",areaCode:"27"},
        {name:"西班牙 (Spain)",areaCode:"34"},
        {name:"斯里蘭卡 (Sri Lanka)",areaCode:"94"},
        {name:"蘇丹 (Sudan)",areaCode:"249"},
        {name:"蘇里南 (Suriname)",areaCode:"597"},
        {name:"斯威士蘭 (Swaziland)",areaCode:"268"},
        {name:"瑞典 (Sweden)",areaCode:"46"},
        {name:"瑞士 (Switzerland)",areaCode:"41"},
        {name:"敘利亞 (Syrian Arab Republic)",areaCode:"963"},
        {name:"塔吉克 (Tajikistan)",areaCode:"992"},
        {name:"坦桑尼亞 (Tanzania)",areaCode:"255"},
        {name:"泰國 (Thailand)",areaCode:"66"},
        {name:"多哥 (Togo)",areaCode:"228"},
        {name:"湯加 (Tonga)",areaCode:"676"},
        {name:"特立尼達和多巴哥 (Trinidad and Tobago)",areaCode:"1868"},
        {name:"突尼斯 (Tunisia)",areaCode:"216"},
        {name:"土耳其 (Turkey)",areaCode:"90"},
        {name:"土庫曼 (Turkmenistan)",areaCode:"993"},
        {name:"土克斯及開科斯群島 (Turks and Caicos Islands)",areaCode:"1809"},
        {name:"烏干達 (Uganda)",areaCode:"256"},
        {name:"烏克蘭 (Ukraine)",areaCode:"380"},
        {name:"阿拉伯聯合酋長國 (United Arab Emirates)",areaCode:"971"},
        {name:"英國 (United Kingdom)",areaCode:"44"},
        {name:"烏拉圭 (Uruguay)",areaCode:"598"},
        {name:"烏茲別克斯坦 (Uzbekistan)",areaCode:"998"},
        {name:"瓦努阿圖 (Vanuatu)",areaCode:"678"},
        {name:"梵蒂岡 (Vatican City State (Holy See))",areaCode:"39"},
        {name:"委內瑞拉 (Venezuela)",areaCode:"58"},
        {name:"越南 (Vietnam)",areaCode:"84"},
        {name:"維爾京群島(英國) (Virgin Islands (British))",areaCode:"1284"},
        {name:"維爾京群島(美國) (Virgin Islands (U.S.))",areaCode:"1340"},
        {name:"西撒哈拉 (Western Sahara)",areaCode:"685"},
        {name:"也門 (Yemen)",areaCode:"967"},
        {name:"南斯拉夫 (Yugoslavia)",areaCode:"381"},
        {name:"贊比亞 (Zambia)",areaCode:"260"},
        {name:"津巴布韋 (Zimbabwe)",areaCode:"263"},
        {name:"阿布哈茲 (the Republic of Abkhazia)",areaCode:"7"},
        {name:"南奧賽梯 (the Republic of South Ossetia)",areaCode:"7"},
        {name:"澤西島 (Bailiwick of Jersey)",areaCode:"44"},
        {name:"老撾 (Lao People s Democratic Republic)",areaCode:"856"},
        {name:"馬其頓 (The Republic of Macedonia)",areaCode:"389"},
        {name:"聖基茨和尼維斯(The Federation of Saint Kitts and Nevis)",areaCode:"1869"},
        {name:"聖盧西亞島 (Santa Luzia Island)",areaCode:"1758"},
        {name:"聖文森特和格林納丁斯(Saint Vincent and the Grenadines)",areaCode:"1784"},
        {name:"聖多美和普林西比 (Sao Tome and Principe)",areaCode:"239"},
        {name:"南蘇丹共和國 (The Republic of South Sudan)",areaCode:"211"}
      ],

      dataMedia:[{
        type:"WeChat",
        index:0,
      },{
        type:"Telegram",
        index:1,
      },{
        type:"Skype",
        index:2,
      },{
        type:"WhatsApp",
        index:3,
      },{
        type:"LinkedIn",
        index:4,
      }],
    }
  },
  // components: {notice},
  mounted() {
    this.creatQrCode();
    const token = getCookie("token");
    const lan = getCookie("lan");

    this.curLan = lan;
    if (token) {
      this.islogin = true;
      this.getUserinfo();
    }
    // this.getGonggao();
    window.addEventListener("scroll",this.showbtn,true);
  },
  computed: {


    getLabelUserAccount: function () {
      switch (this.userinfo.accountStatus) {
        case 0:
          return this.$t('text147');
        case 1:
          return this.$t('text148');
        case 2:
          return this.$t('text149');
        case 3:
          return this.$t('text150');
      }
      return "";
    },
    getLabelByLan(){
      // console.log(cur, 'cur')
      if(location.href.includes('privacyAgreement')) {
        if(this.curLan === 'el_GR') {
          return "中文（繁体）";
        }
          return "English ";
      }else {
         if(this.$i18n.locale === 'el_GR') {
          return "中文（繁体）";
        }
        return "English";
      }
    }
  },
  methods: {
    getWebsiteConfigCache(attr) {
      const config = this.$store.state.market.websiteConfig;
      // console.log('state.data getWebsiteConfigCache:', config, attr)
      if (null != config && config[attr]) {
        return config[attr];
      } else {
        return attr;
      }
    },
    showDialog(){
      this.dialogClueCollect=true;
    },
    creatQrCode() {
      const url = "https://m.kaiyuanipfs.com/"+getCookie("lan")+"/newVersionAppDownload";
      new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 100,
        height: 100,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },

    getCookieVal(key){
      return getCookie(key);
    },
    push(){
      this.$router.push({path:'/index/noticeDetail',query:{
        data:encodeURI(JSON.stringify(this.gonggaoList[this.currentIndex]))
      }});

    },
    handleCommand(command) {
      // const lan = cookies.get('lan');
      // if(lan === this.curLan) return;
      if (location.href.includes('privacyAgreement')) {
        console.log(this.curLan, 'this.curlan');
        switch (command) {
        case "0":
          this.curLan = 'el_GR'
          setCookie("lan", "en_US");
          break;
        case "1":
          this.curLan = 'en_US'
          setCookie("lan", "el_GR");
          break;
      }
      setTimeout(()=> {
        if(this.$i18n !== this.curLan) {
          location.href = "/index/"+this.curLan+"/privacyAgreement";
        }
      })
      return false;
        //  location.reload();
      }
      switch (command) {
        case "0":
          this.curLan = 'el_GR'
          setCookie("lan", "el_GR");
          break;
        case "1":
          this.curLan = 'en_US'
          setCookie("lan", "en_US");
          break;
      }
      location.reload();
    },

    pusholdPage(position) {
      console.log(position, 'pos', this.baseUrlHost);
      switch (position) {
        case "0":
          location.href = this.baseUrlHost + "/" + this.curLan + "/personal/changePassword";
          break;
        case "1":
          location.href = this.baseUrlHost + "/" + this.curLan + "/personal/userManagement";
          break;
        case "a":
          location.href = this.baseUrlHost + "/" + this.curLan + "/personal/userManagement";
          break;
        case "2":
          this.$router.push({path:'/index/myOrder'})
          break;
        case "3":
          this.visible = true;
          break;
        case "4":
          this.loginout();
          break;
        case "5":
          location.href = this.baseUrlHost + "/" + this.curLan + "/assets/exchangeAccount";
          break;
      }
    },
    pushPage(url,isnew=false) {
      if(null==url){
        this.$router.push({
          path: "/index/appDownload"
        });
        return;
      }
      if(isnew){
        window.open(url,"_");
      }else{
        location.href = url;
      }

    },

    pushPrivacyAgreement() {
      // const lan = cookies.get('lan');
      this.$router.push({path:"/index/"+this.curLan+"/privacyAgreement"})
    },
    async getUserinfo() {
      const result = await this.$http({ url: '/common/user_info'});
      if (result.code == "10002") {
        delCookie("token");
        this.islogin = false;
      } else {
        console.log(result, 'res');
        this.userinfo = result.data;
      }
    },
    async loginout() {
      const result = await this.$http({url: '/user/login_out'});
      const {code, msg} = result;
      if (code == "0") {
        delCookie("token");
        location.href = this.baseUrlHost + '/'+this.curLan+'/login';
      } else {
        this.$notify.error({
          title: 'error',
          message: msg
        });
      }
    },
    closedialog() {
      this.visible = false
    },

    showbtn(){
      let that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop
    },
    totop(){
      var timer = setInterval(function(){
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if(osTop === 0){
          clearInterval(timer);
        }
      },30)
    },

    async submit(){
        const phoneReg = /^1[3456789]\d{9}$/;
        const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
        if(this.dec==""||this.email==""||this.name==""||this.tel=="" || this.mediaCode==""){
          this.$notify.warning(this.$t('text183'));
          return ;
        }
        if(!phoneReg.test(this.tel)){
          this.$refs.itel.focus()
          return ;
        }
        if(!emailReg.test(this.email)){
          this.$refs.iemail.focus()
          return ;
        }
        const result = await this.$http({
          url: '/originClueCollect',
          hostType: '/fe-origin-hashrate-api',
          params: {
            UDMText1__c: this.dec,
            field_Xj6cw__c: this.mediaName+"-"+this.mediaCode,
            field_bm5pu__c: this.email,
            name: this.name,
            tel: '+'+this.areaCode+'-'+this.tel,
            url: window.location.origin + "?bu=OriginStorage"
          }
        });
        const {code,msg} = result;
        if(code=="0"){
          // this.$notify.success(this.$t('text182'));
          this.dec="";
          this.name="";
          this.email="";
          this.tel="";
          this.areaCode=undefined;
          this.dialogClueCollect=false;
          this.$router.push({path:"/index/messageSucceeded"})
        }else{
          this.$notify.error(msg);
        }
    },
    dialogClueCollectClose(){
      this.dialogClueCollect=false;
    }
  }
}
</script>

<style scoped>
.mnav {
  display: flex;
  background: #01020C;
  height: 98px !important;
  justify-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  width: 100%;
}

.logo {
  width: 200px;
  height: 26px;
  float: left;

}

.mnav .navlink {
  color: #B0B0B0;
  text-decoration: none;
  margin-right: 50px;
  white-space: nowrap;
}

.mnav .navlink:hover {
  color: #fff;
}

.mnav .navlinkAct{
  color: #fff;
}

.mnav .loginbtn {
  /*background: #3E26D1;*/
  border: 0;
  width: 92px;
  height: 40px;
  margin: 0 20px;
}

.blockView p {
  font-size: 20px;
  color: #333333;
  margin: 20px 0 9px 0;
}

.blockView span {
  font-size: 14px;
  color: #000000;
}

.footer {
  height: 345px !important;
  background: #000000;
  position: relative;
  overflow: hidden;
}

.footer-container{
  display: flex;
  padding-top: 70px;
  margin-bottom: 40px!important;
}


.footer ul li {
  color: #B0B0B0;
  font-size: 14px;
  line-height: 30px;
  list-style: none;
}

.footer p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 20px;
}


.el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus {
  background-color: #333
}

.footer ul li:hover{
  color: #fff;
  cursor: pointer;
}

#iconbottom img{
  filter: brightness(.5);
  cursor: pointer;
}
#iconbottom img:hover{
  filter: brightness(1);
}

.rightutil{
  /*width: 64px;*/
  height: auto;

  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -150px;
  border-radius: 5px;
  z-index: 100;
  overflow: hidden;
}
.rightutil .itembtn{
  width: 50px;
  /*height:70px;*/
  padding:10px 10px;
  background: linear-gradient(#615FE9,#3E26D1);
  flex-direction: column;
  display:flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  text-align: center;

}
.rightutil .itembtn:hover{
  opacity: .9;
}

.rightutil .itembtn:nth-child(3){
  background: linear-gradient(#FBBA4C,#FFA34C);
}
.rightutil .itembtn span{
  font-size: 12px;
  color: #fff;
  padding-top: 5px;
  display: block;
  /*white-space: nowrap;*/
  /*overflow: hidden;*/
  max-width: 100%;
  /*padding-left: 10px;*/
  /*padding-right: 10px;*/
  /*text-overflow: ellipsis;*/
}
.rightutil .itembtn img{
  margin-top: 10px;

}
.el-form-item{
  margin-bottom: 12px;
}
.container{
  width: 1200px;
  margin: 0 auto;
}
.footerLink{
  margin-left: 40px;
  padding-left: 40px;
}

</style>
<style>
.origin-index .el-dropdown-menu__item {
  line-height: 42px !important;
}
.userAccount-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.userAccount p {
  color: #fff;
  line-height: 14px !important;
}
.userAccount span {
  line-height: 12px !important;
}
</style>
