import Vue from 'vue';
import Main from './main';

const AlertBoxConstructor = Vue.extend(Main);

let example = null;

const AlertBox = (options = {}) => new Promise((resolve, reject) => {
  if (Vue.prototype.$isServer) return;
  example = new AlertBoxConstructor({
    propsData: options,
  });

  const instance = example;
  instance.$mount();
  document.body.appendChild(instance.$el);
  instance.isShow = true;
  // 监听确认事件
  instance.$on('confirm', () => {
    resolve();
  });
  // 监听取消事件
  instance.$on('cancel', () => {
    reject();
  });
});

AlertBox.close = () => {
  example.cancel();
};

export default AlertBox;
