import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import market from './market';


Vue.use(Vuex);

export default new Vuex.Store({
    state: { },
    mutations: { },
    actions: {},
    modules: {
        common,
        market
    },
});
