<template>
  <el-container style="background: #fff" direction="vertical">
    <div class="itemView">
      <img :src="detail.thumbnail2Detail ? detail.thumbnail2Detail[0] : require('@assets/pic_suanlishichang.png')" style="width: 500px;" />
      <div style="flex: 1; margin-left: 120px">
        <div style="display: flex; align-items: center">
          <h2 style="margin-right: 20px">
            {{ detail.title }}
          </h2>
          <div :class="['tag', { ['status' + detail.productStatus]: true }]">
            {{ $t("text200." + detail.productStatus) }}
          </div>
        </div>

        <div class="rowitemdec">
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text22") }}</p>
            <p class="labelval">{{ detail.miningCycle }}{{ $t("text181") }}</p>
          </div>
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text48") }}</p>
            <p class="labelval">{{ detail.serviceRate }}%</p>
          </div>
        </div>

        <div class="rowitemdec">
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text49") }}</p>
            <p class="labelval">T + {{detail.incomeType}}</p>
          </div>
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text168") }}</p>
            <p class="labelval">{{ detail.paySymbol }}</p>
          </div>
        </div>

        <div class="rowitemdec">
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text50") }}</p>
            <p class="labelval">
              {{ $t("text169") + " " + detail.minBuy }}T ~ {{ detail.maxBuy + 'T' }}
            </p>
          </div>
          <div style="flex: 1">
            <p class="labelfont">
              {{ $t("text51") }}

              <el-popover
                placement="right"
                width="200"
                trigger="hover"
                :content="$t('text158')"
              >
                <img
                  src="@assets/tip.png"
                  style="width: 16px; position: relative; top: 2px"
                  slot="reference"
                />
              </el-popover>
            </p>

            <p class="labelval">{{ detail.profitRatio }}%</p>
          </div>
        </div>

        <div class="rowitemdec">
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text52") }}</p>
            <p class="labelval" style="font-size: 24px; color: #3e26d1">
              {{ detail.price }} {{ detail.paySymbol | filterSymbol }}/T
            </p>
          </div>
        </div>

        <div class="rowitemdec">
          <div style="flex: 1">
            <p class="labelfont">{{ $t("text157") }}</p>

            <el-input-number
              v-model="count"
              :precision="2"
              :step="detail.stepBuy"
              :max="detail.maxBuy"
              :min="detail.minBuy"
              style="margin-top: 10px"
            ></el-input-number>
          </div>
        </div>
        <!--      立即购买-->
        <el-button
          size="lg"
          v-text="$t('text42')"
          type="primary"
          class="btn"
          :disabled="count == 0 || detail.maxBuy == 0"
          @click="buy"
        />
      </div>
    </div>

    <!--    底部详情-->
    <div class="container">
      <h3>{{ $t("text43") }}</h3>

      <div class="line" />

      <!--      <h3 class="fonttitle">{{$t('text44')}}</h3>-->
      <div class="commonfont markdown-body">
          <VueMarkdown :source="explain" :toc-anchor-link="false" :toc="true"></VueMarkdown>
      </div>
    </div>

    <!--   购买dialog -->
    <el-dialog :visible="visible" class="dialog-styl" :before-close="closeHandler">
      <div slot="title" style="display: flex; align-items: center">
        <img src="@assets/ic_tishi.png" style="width: 40px; height: 40px" />
        <h2 style="margin-left: 14px; font-size: 18px">{{ $t("text163") }}</h2>
      </div>

      <div class="dialogitem" style="padding-top: 0">
        <span>{{ $t("text160") }}</span>
        <span>{{ payAmount + ' ' + detail.paySymbol }}</span>
      </div>
      <div class="dialogitem">
        <span>{{ $t("text161") }}</span>
        <span>{{ count }}T</span>
      </div>
      <!-- 支付方式 -->
      <div v-if="accountList.length">
       <div class="payment-account_wrap">
          <span class="payment-title">
            {{$t('accountTypeLabel')}}
          </span>
          <el-select v-model="accountValue" @change="onSetAccount" class="payment-select">
            <el-option
              v-for="(val, index) in accountList"
              :key="index"
              :label="$t(`accountTypeValue.${val.accountType}`)"
              :value="index">
            </el-option>
          </el-select>
       </div>
       <div class="account_styl">
         <p><span>{{ $t('balance') }} : </span>{{ accountList[accountIndex].balance }} {{ detail.paySymbol }}</p>
         <p><span>{{ $t('buyAmount') }} : </span>{{ accountList[accountIndex].buyAmount }} T</p>
       </div>
      </div>
      <!-- accountTypeLabel -->
      <!-- <el-form label-position="top" label-width="80px" style="margin-top: 19px">
        <el-form-item :label="$t('text162')">
          <el-input
            :placeholder="$t('text165')"
            v-model="ga"
            type="text"
            :maxlength="6"
          ></el-input>
        </el-form-item>
      </el-form> -->

      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="visible = false">{{ $t("text152") }}</el-button> -->
        <!-- :disabled="ga == '' || ga.length != 6" -->
        <el-button type="primary" @click="nextStep" v-preventReClick>{{
          $t('nextStep')
        }}</el-button>
      </div>
    </el-dialog>
    <!--   google 弹框 -->
    <el-dialog
      :visible="googleVisible"
      width="500px"
      :before-close="closeHandler"
    >
      <div slot="title" style="display: flex; align-items: center">
        <img src="@assets/ic_tishi.png" style="width: 40px; height: 40px" />
        <h2 style="margin-left: 14px; font-size: 18px">{{ $t("text163") }}</h2>
      </div>
      <el-form label-position="top" label-width="80px">
        <el-form-item :label="$t('text162')">
          <el-input
            :placeholder="$t('text165')"
            v-model="ga"
            type="text"
            :maxlength="6"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="googleVisible = false">{{
          $t("text152")
        }}</el-button>
        <el-button
          type="primary"
          :disabled="ga == '' || ga.length != 6"
          @click="zhifu"
          v-preventReClick
          >{{ $t("text164") }}</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import { multiply,subtract } from "../../utils/commonUtil";
import { getCookie } from "../../utils/cookieUtil";
import VueMarkdown from 'vue-markdown';
export default {
  name: "suanlidetail",
  components: {
    VueMarkdown,
  },
  data() {
    return {
      detail: {},
      count: 0,
      visible: false,
      googleVisible: false,
      ga: "",
      explain: null,
      lan: getCookie("lan"),
      token: getCookie("token"),
      isClick: false,
      loadingPower: true,
      accountList: [],
      accountTypeActive: true,
      accountValue: "",
      accountIndex: 0,
    };
  },
  computed: {
    s_googleStatus() {
      return this.$store.state.common.userInfo?.googleStatus;
    },
    payAmount: function () {
      return multiply(this.count || "", this.detail.price || "");
    },
  },

  filters: {
    filterSymbol(item) {
      if (!item) {
        return item;
      }
      return item.toLowerCase() == "usdt" ? "$" : item;
    },
  },
  mounted() {
    const id = this.$route.params.id;
    this.getDetailById(id);
    if(this.token) {
      this.getAccount(id);
    }
  },
  methods: {
    onSetAccount(val) {
      if(val === this.accountTypeActive) return;
      this.accountIndex = val;
    },
    toLoading() {
      return this.$loading({
        background: 'rgba(0, 0, 0, 0.2)',
      })
    },
    async getAccount(id) {
      const account = await this.$http({
        url: '/getUserAccountByUid',
        hostType: '/fe-origin-storage-api',
        params: {
          productId: id,
        }
      });
      console.log(account);
      if (account.code.toString() === "0") {
        this.accountList = account.data;
        this.accountList.forEach((element,index) => {
          if(element.accountType.toString() === "0") {
            element.accountTypeActive = true;
            this.accountIndex = index;
            this.accountValue = this.$t(`accountTypeValue.${index}`);
          } else {
            element.accountTypeActive = false;
          }
          
        });
      }
    },
    async getDetailById(id) {
      this.toLoading()
      const detail = await this.$http({
        url: '/project_sku_detail',
        hostType: "/fe-origin-hashrate-api",
        params: {
          productId: id,
        }
      });
      const { code, data } = detail;
      this.toLoading().close();
      this.loadingPower = false
      if (code == "0") {
        this.detail = data;
        this.count = data.minBuy;
        const productMinerIntroduce = data.attrs[this.lan].productMinerIntroduce;
        this.explain = productMinerIntroduce[0]? productMinerIntroduce[0].description : null;
      }

    },
    buy() {
      // if (!this.token) {
        // this.$notify.error(this.$t("text167"));
        // location.href = this.baseUrlHost + "/" + this.$i18n.locale + "/login";
      this.$router.push({
        path: "/index/appDownload"
      });
      return;
      // }
      // this.visible = true;
    },
    nextStep() {
      if (subtract(this.accountList[this.accountIndex].balance, this.payAmount) < 0) {
        this.$_toast.error(this.$t(`accountTypeValue.${this.accountIndex}`) + ' ' + this.$t('notEnoughBalance'));
        return;
      }
      if (this.s_googleStatus) {
        this.googleVisible = true;
        this.visible = false;
      } else {
        this.$_toast.warning(this.$t('text196'));
        location.href =
          this.baseUrlHost +
          "/" +
          this.$i18n.locale +
          "/personal/bindGoogle?link=" +
          window.location.href;
      }
    },
    // async confirmHandler() {
    //   const { balance, surplusTokenAmount } = this.detail;
    //   if (surplusTokenAmount == 0 || this.count > surplusTokenAmount) {
    //     this.$_toast.warning(this.$t("text199"));
    //     return;
    //   }
    //   if (Number(balance) < this.payAmount) {
    //     this.$_toast.success(this.$t("text166"));
    //     return;
    //   }
    //   //支付接口
    //   this.zhifu();
    // },
    closeHandler() {
      this.visible = false;
      this.googleVisible = false;
      this.ga = "";
    },

    async zhifu() {
      if(this.isClick) return;
      this.isClick = true;
      const result = await this.$http({
        url: "/userPayMinerOrderV2",
        hostType: "/fe-origin-storage-api",
        params: {
          buyAmount: this.count,
          productId: this.$route.params.id,
          // payAmount: this.payAmount,
          googleCode: this.ga,
          accountType: this.accountList[this.accountIndex].accountType,
        },
      });
      const { code, msg } = result;
      this.isClick = false;
      if (code == "0") {
        this.$_toast.success(this.$t("text170"));
        const id = this.$route.params.id;
        this.closeHandler();
        this.getDetailById(id);
        this.getAccount(id);
      } else {
        this.$_toast.error(msg);
      }
    },
  },
};
</script>

<style scoped>
.itemView {
  width: 1200px;
  /*height: 363px;*/
  background: #fff;
  padding-bottom: 30px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 50px;
}
.labelfont {
  font-size: 12px;
  color: #999999;
  text-align: left;
}
.labelval {
  font-size: 14px;
  color: #333333;
}

.btn {
  width: 170px;
  height: 44px;
  margin-top: 20px;
}

.rowitemdec {
  display: flex;
  margin-top: 20px;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.commonfont {
  color: #999999;
  font-size: 14px;
  margin-top: 13px;
  margin-bottom: 80px;
}

.fonttitle {
  font-size: 16px;
  color: #000000;
  margin-top: 30px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-top: 20px;
  margin-bottom: 30px;
}
.dialogitem {
  width: 100%;
  height: 51px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  line-height: 51px;
}
.dialogitem span {
  display: block;
  flex: 1;
}
.dialogitem span:nth-child(2) {
  text-align: right;
}

.tag {
  width: 64px;
  height: 24px;
  color: #fff;

  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.status0 {
  /*预售中*/
  background: url("~@/assets/yushouzhong.png") no-repeat center;
}
.status1 {
  /*出售中*/
  background: url("~@/assets/chushouzhong.png") no-repeat center;
}
.status2 {
  /*已售罄*/
  background: #f6f6f6; /*Old gradient for webkit*/
  color: #999;
}
.payment-select {
  width: 280px;
}
.account_styl {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.account_styl p {
  line-height: 22px;
  font-size: 14px;
  font-family: DINPro-Medium, DINPro;
  font-weight: 500;
  color: #333333;
  padding-left: 30px;
}
.account_styl p:first-child {
  padding-left: 0;
}
.account_styl span {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
}
.payment-account_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  margin-top: 29px;
}
.payment-title {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #999999;
  padding-left: 10px;
}
</style>
<style>
 .payment-account_wrap .el-input__inner {
   border: none;
   text-align: right;
   font-size: 14px;
   font-family: PingFangSC-Medium, PingFang SC;
   font-weight: 500;
   color: #333333;
 }
 .dialog-styl .el-dialog {
   width: 600px;
}
</style>
