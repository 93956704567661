<template>
    <div class="download_container">
        <div id="content">
            <div class="appdec">
                <h2>{{$t('appdownText1')}}</h2>
                <p>{{$t('appdownText2')}}</p>

                <div class="btnGroup">
                    <div v-show="ios_download_url" class="downloadbtn" @click="jumpPage(ios_download_url)">
                        <img src="@assets/iosxuanzhong.png" alt="" >
                        <span>iPhone</span>
                        <div class="qrcode_container">
                            <div class="qrcode" ref="qrCodeUrl_ios"/>
                            <p class="scan_text">{{$t('text198')}}</p>
                        </div>
                    </div>
                    <div v-show="android_download_url" class="downloadbtn" @click="jumpPage(android_download_url)">
                        <img src="@assets/androidxuanzhong.png" alt="">
                        <span>Android</span>
                        <div class="qrcode_container">
                            <div class="qrcode" ref="qrCodeUrl_android"/>
                            <p class="scan_text">{{$t('text198')}}</p>
                        </div>
                    </div>
                    <div v-show="ios_tf_download_url" class="downloadbtn" @click="jumpPage(ios_tf_download_url)">
                        <img src="@assets/ic_xiazaixuanzhong.png" alt="">
                        <span>iPhone {{$t('testVersion')}}</span>
                        <div class="qrcode_container">
                            <div class="qrcode" ref="qrCodeUrl_iostf"/>
                            <p class="scan_text">{{$t('text198')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img :src="getWebsiteConfigCache('pcPhoneView')" id="pic"/>
            </div>

        </div>
    </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
    name: 'OrepoolPcWebAppdownload',

    data() {
        return {
            android_download_url:"",
            ios_download_url:"",
            ios_tf_download_url:""
        };
    },

    mounted() {
        this.getAppDownloadInfo();
    },

    methods: {
      getWebsiteConfigCache(attr) {
        const config = this.$store.state.market.websiteConfig;
        console.log('state.data getWebsiteConfigCache:', config, attr)
        if (null != config && config[attr]) {
          return config[attr];
        } else {
          return attr;
        }
      },
        getAppDownloadInfo(){
            this.$http({
                url:"/common/app_download",
                hostType:"/fe-ex-api",
            })
            .then(response=>{
                if(response.code=='0'){
                    const {android_download_url,ios_download_url,ios_testFlight_url} = response.data;
                    this.android_download_url=android_download_url;
                    this.ios_download_url=ios_download_url;
                    this.ios_tf_download_url=ios_testFlight_url;
                    new QRCode(this.$refs.qrCodeUrl_ios, {
                        text: ios_download_url, // 需要转换为二维码的内容
                        width: 100,
                        height: 100,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    new QRCode(this.$refs.qrCodeUrl_android, {
                        text: android_download_url, // 需要转换为二维码的内容
                        width: 100,
                        height: 100,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                    new QRCode(this.$refs.qrCodeUrl_iostf, {
                        text: ios_testFlight_url, // 需要转换为二维码的内容
                        width: 100,
                        height: 100,
                        colorDark: '#000000',
                        colorLight: '#ffffff',
                        correctLevel: QRCode.CorrectLevel.H
                    })
                }else{
                    this.$notify.error(response.msg);
                }

            })
        },
        jumpPage(url){
            window.open(url)
        }
    },
};
</script>

<style scoped>
.download_container{
    background: #01020C;
}
.btnGroup{
    display: flex;
    margin-top: 40px;


}
.downloadbtn{
    width:150px;
    height:44px;
    border:1px solid #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    opacity: 0.7;
    cursor: pointer;
    position: relative;
}
.downloadbtn:hover{
    opacity: 1.0;
}
.downloadbtn:hover .qrcode_container{

    transform: translateY(0);
    opacity: 1;
}
#content{
    display: flex;
    width:1200px;
    margin:0 auto;
}

#pic{
    width:601px;
    height:608px;
    margin-top: 76px;
    margin-bottom: 52px;
    transition: 0.5s;
}
#pic:hover{
    transform: scale(1.02);
}

h2,p{
    color:#fff;

}

.appdec{
    width:570px
}
.appdec h2{
    font-size: 40px;
    margin-top: 200px;
    position: relative;
}
.appdec p{
    font-size: 18px;
    color:#999999;
    margin-top: 24px;
}

.downloadbtn img{
    width:19px;
    height:19px;
    object-fit:contain
}
.downloadbtn span{
    color:#FFFFFF;
    font-size: 12px;
    margin-left:10px
}



.appdec h2::after{
    content: '_';
    margin-left: 8px;
    color: #3E26D1;

}
.qrcode_container{
    width:170px;
    height:198px;
    position: absolute;
    border:1px solid #fff;
    top:54px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translateY(20px);
    transition: 0.5s;
    opacity: 0;
}
.qrcode{
    width:100px;
    height:100px;
    padding:10px;
    background: #fff;
}
.scan_text{
    color:#FFFFFF;
    font-size: 14px!important;
}
</style>
