/**
 * @content 两个数相加
 * @param augend<number | string> 相加的一个数
 * @param addend<number | string> 相加的第二个数
 * @returns 返回总和 <string>
 */
export function add(augend, addend) {
    let augendPointLength = 0;
    let addendPointLength = 0;
    let lengthMax = 0;
    try {
        augendPointLength = augend.toString().split('.')[1].length;
    } catch (e) {
        augendPointLength = 0;
    }
    try {
        addendPointLength = addend.toString().split('.')[1].length;
    } catch (e) {
        addendPointLength = 0;
    }
    lengthMax = 10 ** Math.max(augendPointLength, addendPointLength);
    const result =
        (Number(augend) * lengthMax + Number(addend) * lengthMax) / lengthMax;
    return result.toString();
}

/**
 * @content 两个数相减
 * @param minuend <number | string> 相减的第一个数
 * @param subtrahend <number | string> 相减的第二个数
 * @returns <string> 返回差
 */
export function subtract(
    minuend,
    subtrahend,
) {
    let minuendPointLength = 0;
    let subtrahendPointLength = 0;
    let lengthMax = 0;
    try {
        minuendPointLength = minuend.toString().split('.')[1].length;
    } catch (e) {
        minuendPointLength = 0;
    }
    try {
        subtrahendPointLength = subtrahend.toString().split('.')[1].length;
    } catch (e) {
        subtrahendPointLength = 0;
    }
    lengthMax = 10 ** Math.max(minuendPointLength, subtrahendPointLength);
    const result =
        (Number(minuend) * lengthMax - Number(subtrahend) * lengthMax) / lengthMax;
    return result.toString();
}


/**
 * @content 两个数相乘
 * @param augend <number | string> 相乘的第一个数
 * @param addend <number | string> 相乘的第二个数
 * @returns <number> 返回乘积
 */

  export function multiply(augend, addend) {
    let totalLength = 0;
    const augendString = augend.toString();
    const addendString = addend.toString();
      try{totalLength+=augendString.split(".")[1].length}catch(e){console.log(e)}
      try{totalLength+=addendString.split(".")[1].length}catch(e){console.log(e)}
      return Number(augendString.replace(".","")) * Number(addendString.replace(".","")) / Math.pow(10,totalLength);
  }

/**
 * @content 两个数相除
 * @param dividend<string | number> 相除的第一个数
 * @param divisor<string | number> 相除的第二个数
 * @returns <string>
 */
export function divide(
    dividend,
    divisor,
) {
    if (!dividend) {
        return '0';
    }
    if (!divisor) {
        return '0';
    }
    let dividendPointLength = 0;
    let divisorPointLength = 0;
    const dividendString = dividend.toString();
    const divisorString = divisor.toString();
    try {
        dividendPointLength = dividendString.split('.')[1].length;
    } catch (e) {
        console.error(e);
    }
    try {
        divisorPointLength = divisorString.split('.')[1].length;
    } catch (e) {
        console.error(e);
    }

    let result =
        Number(dividendString.replace('.', '')) /
        Number(divisorString.replace('.', '')) /
        10 ** Math.abs(divisorPointLength - dividendPointLength);
    return result.toString();
}


// 时间戳格式化
export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}
function padLeftZero (str) {
    return ('00' + str).substr(str.length);
}

// 时间小于 10 补 0 方法
export const fillingZero = (n) => (n < 10 ? `0${n}` : n);

/**
 * @content 根据开始时间到今 转换日数,年数等
 * @params start 开始时间,默认2017-09-20
 * @params isTimestamp 是否是时间戳
 * */
export const secondsToTime = (start = '2017-09-20', isTimestamp = false) => {
  let currentStart = start;
  if (isTimestamp) {
    currentStart /= 1000;
  }
  const now = new Date().getTime();
  const startTime = new Date(currentStart).getTime();
  const diff = now - startTime;
  const s = fillingZero(Math.floor(diff / 1000));
  const m = fillingZero(Math.floor(diff / 1000 / 60));
  const h = fillingZero(Math.floor(diff / 1000 / 60 / 60));
  const day = fillingZero(Math.floor(diff / 1000 / 60 / 60 / 24));
  const month = Math.floor(diff / 1000 / 60 / 60 / 24 / 30);
  const year = Math.floor(diff / 1000 / 60 / 60 / 24 / 30 / 12);
  return {
    s,
    m,
    h,
    day,
    month,
    year,
  };
};

/**
 * @content 处理科学计数法
 * @param num <string | number> 带有科学计数法 e 的值
 * @returns <string> 处理完后的字符串
 */
const toNonExponential = (num) => {
  const m = Number(num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
  return Number(num).toFixed(Math.max(0, (m[1] || '').length - Number(m[2])));
};

/**
 * @content 格式化数字小数位数,多余截取
 * @param num <number | stirng> 需要格式化的数字
 * @param fractionDigits <number> 位数
 * @returns <string>  格式化后的值
 */
export function splitFormat(
  num,
  fractionDigits = 0,
) {
  if (num == null) {
    return '--';
  }
  if (Number.isNaN(num)) {
    return '--';
  }
  if (num.toString().includes('e') || num.toString().includes('E')) {
    // eslint-disable-next-line no-param-reassign
    num = toNonExponential(num);
  }

  if (fractionDigits === 0) {
    return parseInt(Number(num).toString(), 10).toString();
  }

  // 如果有小数点
  if (num.toString().includes('.')) {
    const digit = num.toString().split('.');
    // 如果小数点长度不足,补 0
    if (digit[1].length < fractionDigits) {
      const digitResult = ''.padEnd(fractionDigits - digit[1].length, '0');
      return num + digitResult;
    }
    // 如果小数点长度多余,截取
    const digitResult = digit[1].substring(0, fractionDigits);
    return `${digit[0]}.${digitResult}`;
  }
  // 如果没有小数点,补小数点和 0
  const digitResult = '.'.padEnd(fractionDigits + 1, '0');
  return num + digitResult;
}

/**
 * @content 汇率转化
 * @param num <number | stirng> 当前币种数量
 * @param exrate <Object> 汇率转化列表
 * @param market <string> 当前币种
 * @param targetMarket <string> 目标币种
 * @param symbolPrecision <string ｜ number> 币种精度
 *  @returns <string｜number> 返回目标币种数量
 */
export const fixRate = (num, exrate, market, targetMarket, symbolPrecision) => {
  if (!exrate) {
    return '--';
  }
  const [larate] = exrate.filter((item) => (item.baseSymbol === market
    && item.quoteSymbol === targetMarket));
  if (!larate) {
    return '--';
  }
  const pric = larate.rate * num;
  if (`${parseFloat(pric)}` !== 'NaN' && symbolPrecision) {
    return pric.toFixed(symbolPrecision);
  }
  return '--';
};

const colorMap = {
  aliceblue: '#f0f8ff',
  antiquewhite: '#faebd7',
  aqua: '#00ffff',
  aquamarine: '#7fffd4',
  azure: '#f0ffff',
  beige: '#f5f5dc',
  bisque: '#ffe4c4',
  black: '#000000',
  blanchedalmond: '#ffebcd',
  blue: '#0000ff',
  blueviolet: '#8a2be2',
  brown: '#a52a2a',
  burlywood: '#deb887',
  cadetblue: '#5f9ea0',
  chartreuse: '#7fff00',
  chocolate: '#d2691e',
  coral: '#ff7f50',
  cornflowerblue: '#6495ed',
  cornsilk: '#fff8dc',
  crimson: '#dc143c',
  cyan: '#00ffff',
  darkblue: '#00008b',
  darkcyan: '#008b8b',
  darkgoldenrod: '#b8860b',
  darkgray: '#a9a9a9',
  darkgreen: '#006400',
  darkgrey: '#a9a9a9',
  darkkhaki: '#bdb76b',
  darkmagenta: '#8b008b',
  darkolivegreen: '#556b2f',
  darkorange: '#ff8c00',
  darkorchid: '#9932cc',
  darkred: '#8b0000',
  darksalmon: '#e9967a',
  darkseagreen: '#8fbc8f',
  darkslateblue: '#483d8b',
  darkslategrey: '#2f4f4f',
  darkturquoise: '#00ced1',
  darkviolet: '#9400d3',
  deeppink: '#ff1493',
  deepskyblue: '#00bfff',
  dimgray: '#696969',
  dimgrey: '#696969',
  dodgerblue: '#1e90ff',
  firebrick: '#b22222',
  floralwhite: '#fffaf0',
  forestgreen: '#228b22',
  fuchsia: '#ff00ff',
  gainsboro: '#dcdcdc',
  ghostwhite: '#f8f8ff',
  gold: '#ffd700',
  goldenrod: '#daa520',
  gray: '#808080',
  green: '#008000',
  greenyellow: '#adff2f',
  grey: '#808080',
  honeydew: '#f0fff0',
  hotpink: '#ff69b4',
  indianred: '#cd5c5c',
  indigo: '#4b0082',
  ivory: '#fffff0',
  khaki: '#f0e68c',
  lavender: '#e6e6fa',
  lavenderblush: '#fff0f5',
  lawngreen: '#7cfc00',
  lemonchiffon: '#fffacd',
  lightblue: '#add8e6',
  lightcoral: '#f08080',
  lightcyan: '#e0ffff',
  lightgoldenrodyellow: '#fafad2',
  lightgray: '#d3d3d3',
  lightgreen: '#90ee90',
  lightgrey: '#d3d3d3',
  lightpink: '#ffb6c1',
  lightsalmon: '#ffa07a',
  lightseagreen: '#20b2aa',
  lightskyblue: '#87cefa',
  lightslategrey: '#778899',
  lightsteelblue: '#b0c4de',
  lightyellow: '#ffffe0',
  lime: '#00ff00',
  limegreen: '#32cd32',
  linen: '#faf0e6',
  magenta: '#ff00ff',
  maroon: '#800000',
  mediumaquamarine: '#66cdaa',
  mediumblue: '#0000cd',
  mediumorchid: '#ba55d3',
  mediumpurple: '#9370db',
  mediumseagreen: '#3cb371',
  mediumslateblue: '#7b68ee',
  mediumspringgreen: '#00fa9a',
  mediumturquoise: '#48d1cc',
  mediumvioletred: '#c71585',
  midnightblue: '#191970',
  mintcream: '#f5fffa',
  mistyrose: '#ffe4e1',
  moccasin: '#ffe4b5',
  navajowhite: '#ffdead',
  navy: '#000080',
  oldlace: '#fdf5e6',
  olive: '#808000',
  olivedrab: '#6b8e23',
  orange: '#ffa500',
  orangered: '#ff4500',
  orchid: '#da70d6',
  palegoldenrod: '#eee8aa',
  palegreen: '#98fb98',
  paleturquoise: '#afeeee',
  palevioletred: '#db7093',
  papayawhip: '#ffefd5',
  peachpuff: '#ffdab9',
  peru: '#cd853f',
  pink: '#ffc0cb',
  plum: '#dda0dd',
  powderblue: '#b0e0e6',
  purple: '#800080',
  rebeccapurple: '#663399',
  red: '#ff0000',
  rosybrown: '#bc8f8f',
  royalblue: '#4169e1',
  saddlebrown: '#8b4513',
  salmon: '#fa8072',
  sandybrown: '#f4a460',
  seagreen: '#2e8b57',
  seashell: '#fff5ee',
  sienna: '#a0522d',
  silver: '#c0c0c0',
  skyblue: '#87ceeb',
  slateblue: '#6a5acd',
  slategray: '#708090',
  snow: '#fffafa',
  springgreen: '#00ff7f',
  steelblue: '#4682b4',
  tan: '#d2b48c',
  teal: '#008080',
  thistle: '#d8bfd8',
  tomato: '#ff6347',
  turquoise: '#40e0d0',
  violet: '#ee82ee',
  wheat: '#f5deb3',
  white: '#ffffff',
  whitesmoke: '#f5f5f5',
  yellow: '#ffff00',
  yellowgreen: '#9acd32',
};

/**
 * 把十六进制颜色转换为 rgba
 * @param hex 十六进制颜色
 * @param opacity 透明度
 * @return string rgba 颜色
 * */
export const hexToRgba = (hex, opacity) => {
  const newHex = colorMap[hex] ?? hex;
  return (
    `rgba(${
      parseInt(`0x${newHex.slice(1, 3)}`, 10)
    },${
      parseInt(`0x${newHex.slice(3, 5)}`, 10)
    },${
      parseInt(`0x${newHex.slice(5, 7)}`, 10)
    },${
      opacity
    })`
  );
};

// 获取币种信息
export function getCoinInfo(name, coinList) {
  if (!coinList) throw new Error('缺少币种列表');
  const NAME = name.toUpperCase();
  return coinList[NAME];
}

/**
 *
 * @content 返回币种展示名称
 * @param name:string 币种名称
 * @param coinList: CoinList 币种列表
 * @returns
 * name: 币种名称 或者 showName: 币种别名
 */

export function getCoinShowName(name, coinList) {
  if (!name) return '';
  return getCoinInfo(name, coinList)?.showName ?? name;
}

// 获取币种展示精度
export function getCoinPrecision(name, coinList) {
  if (!name) {
    return 4;
  }
  return getCoinInfo(name, coinList)?.showPrecision ?? 4;
}

// 获取域名
export function getJumpHost() {
  if (process.env.NODE_ENV === 'development') {
    return {
      jumpHost: process.env.BASE_URL,
      domain: process.env.DOMAIN,
    };
  }
  if (process.client) {
    const { hostname } = window.location;
    const domainArray = hostname.split('.');
    if (domainArray.length === 3) {
      domainArray.shift();
    }
    const domain = domainArray.join('.');
    return {
      jumpHost: `${process.env.HOST}.${domain}`,
      domain: `.${domain}`,
    };
  }
  return {};
}

// log服务端接口报错信息
export function logServerError(e) {
  console.error('---请求失败----');
  console.error(e);
  console.error({
    code: e?.code,
    url: e?.config?.url,
    headers: e?.config?.headers,
    data: e?.config?.data,
    method: e?.config?.method,
    baseURL: e?.config?.baseURL,
  });
  console.error('---请求失败 end----');
}

// 获取url里的参数
export const fixUrl = (name) => {
  const text = window.location.search.substring(1).split('&');
  let v = null;
  for (let i = text.length - 1; i >= 0; i -= 1) {
    const key = text[i].split('=')[0];
    const value = text[i].split('=')[1];
    if (key === name) {
      v = value;
      break;
    }
  }
  return v;
};

export const textSubString = (str, len) => {
  const regexp = new RegExp(/[^\\x00-\\xFF]/g);// 正在表达式匹配中文
  // 当字符串字节长度小于指定的字节长度时
  if (!str) return '--';
  if (str && str.replace(regexp, 'aa').length <= len) {
    return str;
  }
  // 假设指定长度内都是中文
  const m = Math.floor(len / 2);
  for (let i = m, j = str.length; i < j; i += 1) {
    // 当截取字符串字节长度满足指定的字节长度
    if (str.substring(0, i).replace(regexp, 'aa').length >= len) {
      return `${str.substring(0, i)}...`;
    }
  }
  return str;
};
