const en_US = {
    suanlishichang: "Hashrate Market",
    tuoguan: "Mining Market",
    aboutus: "About Us",
    register: "Sign Up",
    login: "Log In",
    liucheng: "Participation Process",
    text1: "Sign up by Email or Mobile ",
    text2: "to start the journey of cloud mining",
    text3: "Purchase",
    text4: "specialize in FIL mining hashrate product portfolio ",
    text5: "The best choice for your wealth growth",
    text6: "Earnings",
    text7: "Download APP",
    text8: "Daily earnings at a glance ",
    text9: "Advantages of Service",
    text10: "Mining Machine Procurement",
    text11: "Authoritative manufacturer, professional order",
    text12: "IDC Server Room Hosting",
    text13: "Clear fees for your convenience",
    text14: "Global Compliance",
    text15: "Global deployment, compliant O&M",
    text16: "Withdraw at Any Time",
    text17: "Instant earnings and unhindered withdrawals",
    text18: "Partners",
    text19: "Hot Picks",
    text20: "FIL Cloud Hashrate",
    text21: "FIL Mining Machine Hosting",
    text22: "Mining Cycle",
    text23: "Special Price",
    text24: "Earnings Rate",
    text25: "Check Details",
    text26: "High return at a low cost",
    text27: "Leasing full effective servers",
    text28: "Start computing servers hosting at one simple click",
    text29: "Energy saving, money saving",
    text30: "Product",
    text31: "Mining Market",
    text32: "About us",
    text33: "Support",
    text34: "Extended Links",
    text35: "",
    text36: "Bulk Order Service",
    text37: "Privacy Policy",
    text38: "Filecoin Official",
    text39: "Filecoin Earnings Calculator",
    text40: "Protocol Labs",
    text41: "Filecoin Foundation",
    text42: "Buy Now",
    text43: "Product Introduction",
    text44: "Guarantee Service",
    text45: "Talk To Our Experts",
    text46: "1+1 Minimum Cluster",
    text47: "Mining Cycle",
    text48: "Service Fee",
    text49: "Settlement Cycle",
    text50: "Buy Restrictions",
    text51: "Earnings Rate",
    text52: "Special Price",
    text53: "T3+ IDC Server Room",
    text54: "$15/T/year",
    text55: "15%",
    text56: "Details Consultation",
    text57: "AMD EPYC 7302 16Core 3.0G",
    text58: "Ultra Micro H11SSL-i",
    text59: "64GB",
    text60: "256GB Samsung SATA 2.5 inch ERP",
    text61: "RTX3080 10G",
    text62: "4U Chassis",
    text63: "1+1 Minimum Cluster one-stop mining service is a mining model with mining machine sales and full hosting (including physical hosting of mining machines and hosting of revenue), which aims to provide users with a convenient one-stop mining service and distributes profits in the form of Filecoin on a daily basis.",
    text64: "<p>1+1 Minimum Cluster one-stop mining service is a mining model with mining machine sales and full hosting (including physical hosting of mining machines and hosting of revenue), which aims to provide users with a convenient one-stop mining service and distributes profits in the form of Filecoin on a daily basis.</p>"+
        "<p>1. This group of products includes one computing power acceleration server and one storage server. </p>"+
        "2. The default contract of one-stop mining service is 3 years. After the expiration of 3 years, users can choose to take back the mining machine or renew the contract.\n" +
        "3. The mining machine will be delivered to the mining farm and the mining process will be started within T+21 days after purchase, the mining machine will be hosted to the mining farm of US by default.\n" +
        "4. The effective computing space of this product is 410T, and there will be some deviation in the actual situation, which is a normal phenomenon.\n" +
        "5. We manages the user's mining machine assets as agreed in this Agreement, and will issue weekly/monthly report details to make the user's asset management process transparent.",
    text65: "Rent computing power, buy storage clusters",
    text67: "Bonus: Central dispatch server + high performance computing power acceleration server",
    text68: "Storage Server*1",
    text69: "30T/day",
    text70: "Central dispatch server + high performance computing power acceleration server",
    text71: "Computing server*1 + storage server*1",
    text72: "1.5T/day",
    text73: "528T (16T*33)",
    text74: "410",
    text75: "2-3weeks",
    text76: "528T/group",
    text77: "410T/group",
    text78: "2～3weeks",
    text79: "T3+ IDC Server Room",
    text80: "$15/T/year",
    text81: "15%",
    text82: "AMD EPYC 7302 16core 3.0G",
    text83: "Ultra Micro H11SSL-i",
    text84: "64GB",
    text85: "256GB Samsung SATA 2.5 inch ERP",
    text86: "RTX3080 10G",
    text87: "4U chassis",
    text88: "One-stop mining service of renting hashrate, buy storage clusters is a mining model with mining machine sales and full hosting (including physical hosting of mining machines and hosting of revenue), which aims to provide users with a convenient one-stop mining service and distributes profits in the form of Filecoin on a daily basis.\n"+
        "The work done by the computing power acceleration server is to do the data sealing in the proof replication phase, and to generate the proof using zero knowledge proof during the PoREP phase. The storage server stores the sealed computing power, and both of them are used for Filecoin mining in collaboration with each other.This product only leases the computing power server to complete its computing power sealing, and the user only has the permanent property rights of the storage machine.",
    text89: "1.This product group includes computing servers and storage servers. You can configure the number of servers based on actual conditions.\n" +
        "2.The default contract of one-stop mining service is 3 years. After the expiration of 3 years, users can choose to take back the mining machine or renew the contract.\n" +
        "3. The mining machine will be delivered to the mining farm and the mining process will be started within T+21 days after purchase, the mining machine will be hosted to the mining farm of US by default. \n" +
        "4. The effective computing power of this product is 410T, and there will be some deviation in the actual situation, which is a normal phenomenon.\n" +
        "5. We manages the user's mining machine assets as agreed in this Agreement, and will issue weekly/monthly report details to make the user's asset management process transparent.",

    text90: "Sealing Efficiency",
    text91: "Property Rights",
    text92: "Permanent",
    text93: "Storage Machine Permanent",
    text94: "Storage Capacity",
    text95: "Effective Computing Power",
    text96: "Property Right of Mining Machine",
    text97: "Delivery Time",
    text98: "IDC Server Room Hosting",
    text99: "Hosting Fee",
    text100: "Tech Service Fee",
    text101: "Main Board",
    text102: "Memory",
    text103: "Memory",
    text104: "System Disk",
    text105: "GPU",
    text106: "Volume",
    text107: "Configuration Parameters",
    text108: "Product Introduction",
    text109: "Sealing Efficiency",
    text110: "FIL Cloud Hashrate ",
    text111: "Leasing full effective servers",
    text112: "High return at a low cost",
    text113: "FIL Mining Machine Hosting",
    text114: "Start computing servers hosting at one simple click",
    text115: "Energy saving, money saving",
    text116: "One-stop Filecoin Mining Platform",
    text117: "Top global O&M team, safe O&M scale up to 10 million",
    text118: "Buy and Earn With One Click",
    text119: "ABOUT US",
    text120: "Development Memorabilia",
    text121: "Why Choose Us",
    text122: "Business Cooperation",
    text123: "Team Members",
    text124: "Partners",
    text125: " is committed to provide better underlying technology and solutions for Web3.0 ecology with safe, flexible and reliable technology and product services.",
    text126: "Machine Procurement",
    text127: "Authoritative Manufacturer, Professional Order",
    text128: "Technical Strength",
    text129: " team members include many of the early code contributors to IPFS",
    text131: " officially launches power recovery tool [Power Recovery Master] in Phase 1 of Space Race",
    text132: "Solve the loss problem of Filecoin computing power! We have also helped miners in need to restore their computing power successfully.",
    text133: "Github Code Contribution",
    text134: " has a leading international blockchain team. The company distributes in the United States, Canada, Japan, Singapore, Hong Kong, with strong technical resources and global dispatch capabilities. The team members are from Stanford University, Oxford University, Peking University, Tsinghua University, Fudan University and other top universities at home and abroad. More than 90% of the team are technical staff, who have worked in Baidu, 360, Ubuntu, Red Hat and other well-known companies at home and abroad, including many early IPFS code contributors.",
    text135: "IDC Room Hosting",
    text136: "Clear fees for your convenience",
    text137: "Withdraw at Any Time",
    text138: "Instant earnings and unhindered withdrawals",
    text139: "Global Compliance",
    text140: "Global deployment, compliant O&M",
    text141: "Password",
    text142: "Google Verification Code",
    text143: "My Order",
    text144: "My Earnings",
    text145: "Exit",
    text146: "Account Status",
    text147: "Normal",
    text148: "Freeze Transaction, Freeze Withdrawal",
    text149: "Freeze Transaction",
    text150: "Freeze Withdrawal",
    text151: "System Alert",
    text152: "Cancel",
    text153: "Download it Now",
    text154: "The web side of the current interface has not yet been developed. Please download the APP to view this feature.",
    text155: "Customer Service",
    text156: "Key Client Service",
    text157: "Quantity",
    text158: "Earnings Rate is calculated based on market conditions, please invest with caution",
    text159: "Announcement",
    text160: "Total Payment",
    text161: "Quantity",
    text162: "Google Verification Code",
    text163: "Product Payment",
    text164: "Confirm",
    text165: "Please enter the verification code",
    text166: "Insufficient account balance!",
    text167: "Account is not logged in!",
    text168: "Revenue Settlement",
    text169: "Only",
    text170: "Purchase success!",
    text171: "Back to top",
    text172: "Chat Online",
    text173: "Beginner Experience",
    text174: "Submit a ticket",
    text175: "Leave us a message",
    text176: "Name",
    text177: "Phone number",
    text178: "Email",
    text179: "Questions",
    text180: "Submit",
    text181: "Day",
    text182: "Submit successfully!",
    text183: "Please enter a value!",
    text184: "Bonus",
    text185:"Deposit",
    text186:"ID",
    text187: "Order Number",
    text188: "Quantity",
    text189: "Hashing power cycle",
    text190: "Service Fee",
    text191: "Settlement Cycle",
    text192: "Pay Price",
    text193: "Settlement Method",
    text194: "Earnings Rate(%)",
    text195:"Home",
    text196:"No binding to googleCode！",
    text197:"App Download",
    text198:"Scan and download",
    text199:"Inventory shortage!",
    text200: ['Pre Sale','On Sale','Sale Ended'],
    text201: "New Hash Power Product",
    text202: "Preparing...",
    text203: "Respect and Expectation",
    text204: "Social media",

    text205: "Advantages of self-owned IPFS super mining pool algorithm",
    text206: "Platform-level Management",
    text207: "A platform which is completely based on IPFS underlying research and development, real-time synchronization with the mainnet, truly based on IPFS file storage system and Filecoin digital asset management: You can manage your digital assets conveniently with one account without logging in to the mainnet.",
    text208: "Independent Mining Farm Management",
    text209: "The mining machine can be initialized to join the farm automatically: the mine equipment can be managed by sub-accounts with different roles according to the needs, and different data can be viewed based on different account permissions.",
    text210: "Support Distributed Deployment and Management",
    text211: "The mine equipment can be distributed and deployed around the world. We have developed our own high-performance storage optimization algorithms, which improved storage read/write efficiency and mining performance by 30%.",
    text212: "Remote Control, Automatic Monitoring",
    text213: "CPU temperature, memory, and hard disk running status can be seen at a glance. It supports active alarm prompt, remote switch operation and remote configuration.",
    text214: "Ten Million Scale, Stable and Efficient",
    text215: "It adopts a three-level management model of pool-farm-machine, with automation, scale and visualization, and the capacity of mining pool reaches 10 million, and a single farm supports 100,000 mining machines.",
    text216: "Seven Levels of Security, Easy Management",
    text217: "For the security requirements of asset management involving coin withdrawals and coin transfers, in addition to the underlying data transmission encryption, mobile verification, mailbox verification, graphic verification, identity verification, Google verification, etc. are all required.",
    submitMessageRestltTitle: "Submitted successfully",
    submitMessageRestltBody: "Thank you for your ticket. We will contact you as soon as possible. Thank you!",
    toHome:"seconds back home",
    filClould: "New computing power products are being prepared…",
    nextStep: 'Next step',
    pollDes: 'Mining machine products are being prepared…',
    stayTuned: 'Stay Tuned',
    orderStatus: 'Order Status',
    orderStatusList: [
         'Paid',
         'Pending Mining',
         'Mining',
         'Ended'
     ],
    orderTime: 'Order Time',
    hashRate_market: 'Hashrate Market',
    accountTypeLabel: "Payment Account",
    accountTypeValue: [
        "Available",
        "Staking",
        "Repayments"
    ],
    balance: "Balance",
    buyAmount: "Purchase Limit",
    notEnoughBalance: "insufficient assets",

    testVersion:"Beta",
    appdownText1:"Mobile APP Download",
    appdownText2:"Multi-Platform Terminal Trading At Anytime and Anywhere"
}

export default en_US;
