const el_GR = {
    suanlishichang:"算力市場",
    tuoguan:"礦機託管",
    aboutus:"關于我們",
    register:"註冊",
    login:"登陸",
    liucheng:"參與流程",
    text1:"通過郵箱和手機開通帳戶",
    text2:"即可開啟算力挖礦之旅",
    text3:"購買",
    text4:"主打FIL挖礦算力組合產品",
    text5:"是你財富增值的最佳選擇",
    text6:"收益",
    text7:"下載APP",
    text8:"每日收益一目了然",
    text9:"服務優勢",
    text10:"礦機採購",
    text11:"權威廠家，專業訂購",
    text12:"機房託管",
    text13:"費用清晰，方便省心",
    text14:"全球合規",
    text15:"全球部署，合規運帷",
    text16:"隨時提現",
    text17:"收益即時，提現無阻",
    text18:"合作夥伴",
    text19:"熱門推薦",
    text20:"FIL雲算力",
    text21:"FIL礦機託管",
    text22:"算力週期",
    text23:"優惠價",
    text24:"收益率",
    text25:"查看詳情",
    text26:"低成本獲取高回報",
    text27:"租賃滿算服務器",
    text28:"壹鍵託管算力服務器",
    text29:"省心省力又省錢",
    text30:"產品",
    text31:"礦機託管",
    text32:"我們",
    text33:"支持",
    text34:"擴展鏈接",
    text35:"",
    text36:"大客戶服務",
    text37:"隱私條款",
    text38:"Filecoin官網",
    text39:"FIL收益計算器",
    text40:"協議實驗室",
    text41:"Filecoin基金會",
    text42:"立即購買",
    text43:"產品介紹",
    text44:"保障服務",
    text45:"聯繫我們",
    text46:"1+1最小集群",
    text47:"算力週期",
    text48:"技術服務費",
    text49:"收益結算",
    text50:"購買限制",
    text51:"預計收益率",
    text52:"優惠價",



    text53:"T3+ IDC機房",
    text54:"$15/T/年",
    text55:"15%",
    text56:"詳情諮詢",
    text57:"AMD EPYC 7302 16核 3.0G",
    text58:"超微H11SSL-i",
    text59:"64GB",
    text60:"256GB 三星 SATA 2.5寸企業級",
    text61:"RTX3080 10G",
    text62:"4U機箱",
    text63:"1+1最小集群一站式挖礦服務即礦機實物銷售和全權託管的挖礦模式(包含礦機實物託管和收益託管)，旨為⽤戶提供便捷的一站式挖礦服務，並於每日進⾏利潤分配，收益以Filecoin的形式進⾏派發。",
    text64:"<p>1+1最小集群一站式挖礦服務即礦機實物銷售和全權託管的挖礦模式(包含礦機實物託管和收益託管)，旨為⽤戶提供便捷的一站式挖礦服務，並於每日進⾏利潤分配，收益以Filecoin的形式進⾏派發。</p>"+
        "<p>1.本組產品包含算力加速服務器和存儲服務器各一台。</p>"+
        "2.一站式挖礦服務默認合約為3年，3年到期後⽤戶可⾃⾏選擇提取礦機，或者續約。\n" +
        "3.購買礦機後 T+21天內發貨⾄礦場上架，礦機默認託管到開源存儲的礦場。\n" +
        "4.本產品有效算力空間為410T，實際情況會有些許偏差，屬正常現象。\n" +
        "5.開源存儲對本協議約定的用戶礦機資產進行管理，並會出具週/月報詳情，讓⽤戶的資產管理過程公開透明。",
    text65:"租算力買存儲集群",
    text67:"贈送：中央調度服務器 + 高性能算力加速服務器",
    text68:"存儲服務器*1",
    text69:"30T/天",

    text70:"中央調度服務器 + 高性能算力加速服務器",
    text71:"算力服務器*1 + 存儲服務器*1",
    text72:"1.5T/天",
    text73:"528T (16T*33)",
    text74:"410",
    text75:"2-3週",
    text76:"528T/組",
    text77:"410T/組",
    text78:"2～3週",
    text79:"T3+ IDC機房",
    text80:"$15/T/年",
    text81:"15%",
    text82:"AMD EPYC 7302 16核 3.0G",
    text83:"超微H11SSL-i",
    text84:"64GB",
    text85:"256GB 三星 SATA 2.5寸企業級",
    text86:"RTX3080 10G",
    text87:"4U機箱",
    text88:"租算力買存儲集群一站式挖礦服務即礦機實物銷售和全權託管的挖礦模式(包含礦機實物託管和收益託管)，旨為⽤戶提供便捷的一站式挖礦服務，並於每日進⾏利潤分配，收益以Filecoin的形式進⾏派發。\n"+
        "算力加速服務器做的工作就是在復制證明階段做數據密封，和PoREP 階段利用零知識證明生成證明的過程，存儲服務器存儲密封好的算力，兩者用於Filecoin挖礦，相互協作，缺一不可。本產品僅租賃算力服務器完成其封裝算力工作，用戶僅有存儲機永久產權。",
    text89:"1.本組產品包含算力服務器和存儲服務器，具體數量可根據實際情況自行配置。\n" +
        "2.一站式挖礦服務默認合約為3年，3年到期後⽤戶可⾃⾏選擇提取礦機，或者續約。\n" +
        "3.購買礦機後 T+21天內發貨⾄礦場上架，礦機默認託管到開源存儲的礦場。\n" +
        "4.本產品有效算力空間為410T * N，實際情況會有些許偏差，屬正常現象。\n" +
        "5.開源存儲對本協議約定的用戶礦機資產進行管理，並會出具週/月報詳情，讓⽤戶的資產管理過程公開透明。",

    text90:"封裝效率",
    text91:"産權",
    text92:"永久",
    text93:"存儲機永久",
    text94:"存儲空間",
    text95:"有效算力",
    text96:"礦機產權",
    text97:"交付時間",
    text98:"機房託管",
    text99:"託管費用",
    text100:"技術服務分成",
    text101:"主板",
    text102:"內存",
    text103:"內存",
    text104:"系統盤",
    text105:"顯卡",
    text106:"體積",
    text107:"配置參數",
    text108:"產品介紹",
    text109:"封裝效率",
    text110:"FIL雲算力",
    text111:"租賃滿算服務器",
    text112:"低成本獲取高回報",

    text113:"FIL礦機託管",
    text114:"壹鍵託管算力服務器",
    text115:"省心省力又省錢",
    text116:"一站式Filecoin挖礦平臺",
    text117:"全球頂級運維團隊，安全運維規模達千萬級",
    text118:"一鍵購買，即刻收益",
    text119:"關於我們",
    text120:"發展歷程·大事記",
    text121:"為什麽選擇我們",
    text122:"商務合作",
    text123:"團隊成員",
    text124:"合作夥伴",
    text125:"致力以安全、靈活、可靠的技術與產品服務，為Web3.0生態提供更優質的底層技術和解決方案。",
    text126:"礦機採購",
    text127:"權威廠家，專業訂購",
    text128:"技術實力",
    text129:"團隊成員不乏IPFS早期代碼貢獻者",
    text131:"在太空競賽第一階段正式推出算力恢復工具「算力恢復大師」",
    text132:"攻克Filecoin丟失算力難題！亦成功幫助有需求的礦工恢復算力。",
    text133:"Github代碼貢獻",
    text134:"擁有國際領先的區塊鏈團隊。公司分佈於美國、加拿大、日本、新加坡、香港，具有強大的技術資源及全球調度能力。團隊成員均來自於斯坦福大學、牛津大學、北京大學、清華大學、復旦大學等海內外一流高校。團隊中90%以上為技術人員，曾在百度、360、Ubuntu、紅帽等國內外知名公司任職，其中不乏IPFS早期代碼貢獻者。",
    text135:"機房託管",
    text136:"費用清晰，方便省心",
    text137:"隨時提現",
    text138:"收益即時，提現無阻",
    text139:"全球合規",
    text140:"全球部署，合規運帷",

    text141:"密碼",
    text142:"Google驗證碼",
    text143:"我的訂單",
    text144:"我的收益",
    text145:"退出",
    text146:"賬戶狀態",
    text147:"正常",
    text148:"凍結交易，凍結提現",
    text149:"凍結交易",
    text150:"凍結提現",
    text151:"系統提示",
    text152:"取消",
    text153:"去下載",
    text154:"當前界面的 web 端尚未開發。請下載應用程序以查看此功能。",
    text155:"客服郵箱",
    text156:"大客戶郵箱",
    text157:"選擇數量",
    text158:"預計收益率根據市場行情計算得出，請大家謹慎投資",
    text159:"公告",
    text160:"總付款",
    text161:"購買數量",
    text162:"谷歌驗證碼",
    text163:"產品支付",
    text164:"確定",
    text165:"請輸入驗證碼",
    text166:"賬戶餘額不足!",
    text167:"賬戶未登錄!",
    text168:"結算方式",
    text169:"僅限",
    text170:"購買成功!",
    text171:"返回頂部",
    text172:"在線交談",
    text173:"新手體驗",
    text174:"提交工單",
    text175:"給我們留言",
    text176:"姓名",
    text177:"電話號碼",
    text178:"電子郵箱",
    text179:"問題",
    text180:"提交",
    text181:"天",
    text182:"提交成功!",
    text183:"不能為空!",
    text184:"贈送",
    text185:"充值",
    text186:"ID",
    text187:"訂單編號",
    text188:"購買數量",
    text189:"算力週期",
    text190:"技術服務費",
    text191:"收益結算",
    text192:"支付價格",
    text193:"結算方式",
    text194:"預計收益率(%)",
    text195:"首頁",
    text196:"沒有綁定googleCode！",
    text197:"App下載",
    text198:"掃描二維碼下載應用",
    text199:"庫存不足!",
    text200: ['預售中', '出售中', '已售罄'],
    text201: "新算力產品",
    text202: "正在準備中…",
    text203: "敬請期待",
    text204: "社交媒體",
    text205: "自有IPFS超級礦池算法優勢",
    text206: "平臺級管理",
    text207: "完全基於IPFS底層研發，實時主網同步，真正基於IPFS文件存儲系統與Filecoin數字資產管理的平臺:無需登錄主網，一個賬號即可便捷的管理自己的數字資產。",
    text208: "礦場獨立管理",
    text209: "礦機可自主初始化加入礦場:礦場設備可以根據需要以分角色子賬戶管理，基於不同賬戶權限查看不同的數據。",
    text210: "支持分佈式部署和管理",
    text211: "礦場設備可以分佈式部署在全球各地，自主研發高性能存儲優化算法，存儲讀寫效率和挖礦效能提升30%。",
    text212: "遠程控製，自動化監控",
    text213: "CPU溫度、內存、硬盤運行狀態一目了然，支持主動報警提示，支持遠程開關機操作和遠程配置。",
    text214: "千萬級規模，穩定高效",
    text215: "採用池-場-機三層管理模式，自動化，規模化，可視化，礦池容量達千萬級，單一礦場支持10萬臺礦機。",
    text216: "七層安全，管理無憂",
    text217: "針對涉及提幣，轉幣等資產管理的安全性要求，除了底層數據傳輸加密以外，手機驗證、郵箱驗證、圖形驗證、身份驗證、谷歌驗證等，一樣都不少。",
    submitMessageRestltTitle: "提交成功",
    submitMessageRestltBody: "感謝您提交的工單，我們會儘快與您聯繫，謝謝！",
    toHome:"秒後返回首頁",
    filClould: "新算力產品正在準備中…",
    nextStep: '下一步',
    pollDes: '礦機產品正在準備中…',
    stayTuned: '敬請期待',
    orderStatus: '訂單狀態',
    orderStatusList: [
        '已支付',
        '待挖礦',
        '挖礦中',
        '已結束'
    ],
    orderTime: '下單時間',
    hashRate_market: '雲算力',
    accountTypeLabel: "付款賬戶",
    accountTypeValue: [
        '可用賬戶',
        '質押賬戶',
        '還款賬戶'
    ],
    balance: "餘額",
    buyAmount: "賬戶最多可購買",
    notEnoughBalance: "資產不足",

    testVersion:"內測版",
    appdownText1:"移動應用下載",
    appdownText2:"隨時隨地進行多平台終端交易"

}
export default el_GR;
