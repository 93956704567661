<template>
  <div id="app">
<!--
    <el-button v-text="'set token'" @click="setToken" style="position:absolute;right: 0;bottom: 20px" v-if="dev"/>
-->
    <router-view />

  </div>
</template>

<script>
import {setCookie} from "./utils/cookieUtil";
import cookie from 'js-cookie';

export default {
  name: 'App',
  data(){
    return {
      dev: process.env.NODE_ENV === 'development'
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init() {
      this.$store.dispatch('getWebsite').then(() => {
        // console.log("getWebsite")
        const title = document.getElementById('htmlTitle');
        const icon = document.getElementById('htmlIcon');
        const config = this.$store.state.market.websiteConfig;
        // console.log("config ",config,icon.href)
        title.innerHTML =  config.title || 'Filecoin IPFS';
        icon.href = config.favicon || '/'
      }).catch( err => {
        console.log(err);
      });
      const token = cookie.get('token');
      if (token) {
        this.$store.dispatch('setToken');
        this.$store.dispatch('getUserInfo');
      }

    },
    setToken(){
      setCookie("token","3154664f64995a64899a73ec612f03badf8e9e73c7b84540b742ed254e3bdb30");
    }
  },

}
</script>

<style lang="css">
*{
  margin:0;padding:0;
}

.popper__arrow{
  display: none!important;
}
.el-dialog__body{
  padding:10px 35px!important;
}
#app {
  min-height: 100vh;
}
</style>
