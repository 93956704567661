import axios from 'axios';
import * as cookies from 'js-cookie';
import Toast from '@/components/Public/Toast';
const formatTime = (dateTime) => {
  const date = new Date(dateTime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  function s(t) {
    return t < 10 ? `0${t}` : t;
  }
  return `${year}-${s(month)}-${s(day)} ${s(hours)}:${s(minutes)}:${s(seconds)}`;
};
// 参数
const formatParams = (acParams = {}) => {
  let data;
  if (Object.prototype.toString.call(acParams) === '[object FormData]') {
    acParams.append('uaTime', formatTime(new Date().getTime()));
    data = acParams;
  } else {
    data = { ...acParams };
    data.uaTime = formatTime(new Date().getTime());
  }
  return data;
};
// 请求头
const formatHeaders = (acHeaders) => {
  let headers = {};
  headers['exchange-token'] = cookies.get('token') || 'c5fa97c1140aafea1ef1e84b67503d5e0db18d0ca0ff4819a0ca3f24722407df';
  headers['exchange-client'] = 'pc';
  headers['exchange-language'] = cookies.get('lan') || 'el_GR';
  if (acHeaders) {
    headers = { ...headers, ...acHeaders };
  }
  return headers;
};

const request = function request(prefix, url, headers, params, method, hostType, resolve, reject) {
  return axios({
    url: `${prefix}${url}`,
    headers: formatHeaders(headers),
    data: formatParams(params),
    method: method || 'post',
  }).then((data) => {
    if (typeof data.data.code !== 'undefined') {
      if (data.data.code.toString() === '10002') {
        cookies.remove('token');
      }
    } else {
        Toast({
            message: '接口返回返回失败'
        });
    }
    resolve(data.data);
  }).catch((err) => {
    reject(err);
    throw new Error(`Error:${err}`);
  });
};

const http = ({
  url, headers, params, method, hostType,
}) => {
  let prefix = '';
  console.log('hostType ',url, hostType)
  switch (hostType) {
    case '/fe-origin-hashrate-api':
      prefix = '/fe-origin-hashrate-api';
      break;
    case '/fe-origin-storage-api':
      prefix = '/fe-origin-storage-api';
      break;
    default:
      prefix = '/fe-ex-api'; // 公共和交易所
  }
  return new Promise((resolve, reject) => {
    request(prefix, url, headers, params, method, hostType, resolve, reject);
  });
};
export default http;
