<script>
export default {
  props: {
    // 消息
    message: {
      type: String,
      default: '',
    },
    // 持续时间
    duration: {
      type: Number,
      default: 2000,
    },
    // 类型 success error warning info
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 是否显示
      visible: false,
    };
  },
  render() {
    return (
      <transition name="v-toast-pop">
        {this.visible ? (
          <div class="v-toast">
            <span class="v-toast__text">
              {this.type && <span class={['v-toast__icon', `v-toast__icon-${this.type}`]}/>}
              <span class="v-toast__message">{this.message}</span>
            </span>
          </div>
        ) : null}
      </transition>
    );
  },
};
</script>

<style>
.v-toast {
  position: fixed;
  top: 138px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20000;
  max-width: 80%;
  border-radius: 4px;
  padding: 10px 40px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  transition: opacity 0.3s linear;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
}
.v-toast__text {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  display: block;
  text-align: center;
}

.v-toast__icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.v-toast__icon-success {
   background: url('images/success.png');
   background-size: cover;
 }
.v-toast__icon-error {
   background: url('images/error.png');
   background-size: cover;
 }
.v-toast__icon-warning {
   background: url('images/warning.png');
   background-size: cover;
 }
.v-toast__icon-info {
   background: url('images/info.png');
   background-size: cover;
 }

.v-toast__message {
  vertical-align: middle;
}

.v-toast-pop-enter,
.v-toast-pop-leave-active {
  opacity: 0;
}

.v-toast-pop-enter-active,
.v-toast-pop-leave-active {
  transition: all 0.3s;
}
</style>
